<template>
    <div class="order">
        <v-container>
            <div v-for="(order, $index) in orders" :key="$index">
                <v-card max-width="600px" class="mb-4 ml-auto mr-auto" @click="openOrderDetails(order)"
                        v-if="!isFilteredItem(order)">
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4">
                                {{
                                $t("view.orderDetail.header", {
                                  shopType: $t("masterData.shopType." + order.shop)
                                })
                                }}
                            </div>
                            <v-list-item-title class="text-h5 mb-1 text-uppercase">
                                {{ $t("masterData.orderState." + order.currentState) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <v-row class="pb-0" v-if="order.archived">
                                    <v-col cols="7">{{ $t("view.orderDetail.labels.archived") }}</v-col>
                                </v-row>
                                <v-row class="pb-0 mt-0 font-weight-bold" v-if="order.description">
                                    <v-col cols="12">{{ order.description }}</v-col>
                                </v-row>
                                <v-row class="pb-0 mt-0">
                                    <v-col cols="7">{{ $t("view.orderDetail.labels.shopNumber") }}</v-col>
                                    <v-col cols="5">{{ order.shopNumber }}</v-col>
                                </v-row>
                                <v-row class="mt-0">
                                    <v-col cols="7" class="pt-1">{{ $t("view.orderDetail.labels.orderNumber") }}</v-col>
                                    <v-col cols="5" class="pt-1">{{ order.orderNumber }}</v-col>
                                </v-row>
                                <v-row class="mt-0"
                                       v-if="order.currentState !== 'DELIVERED' && order.currentState !== 'PICKED_UP' && order.estimatedDeliveryDate">
                                    <v-col cols="7" class="pt-1">{{
                                        $t("view.orderDetail.labels.estimatedDelivery")
                                        }}
                                    </v-col>
                                    <v-col cols="5" class="pt-1">{{
                                        renderEventDate(order.estimatedDeliveryDate)
                                        }}
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0" v-if="order.pictureCount">
                                    <v-col cols="7" class="pt-1">{{
                                        $t("view.orderDetail.labels.pictureCount")
                                        }}
                                    </v-col>
                                    <v-col cols="5" class="pt-1">{{ order.pictureCount }}</v-col>
                                </v-row>
                                <v-row class="mt-0" v-if="order.price">
                                    <v-col cols="7" class="pt-1">{{ $t("view.orderDetail.labels.price") }}</v-col>
                                    <v-col cols="5" class="pt-1">{{ formatCurrency(order.price) }} €</v-col>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                            <v-icon x-large>{{ mapStateToIcon(order.currentState) }}</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </div>

            <infinite-loading @infinite="loadOrders">
                <!-- hide default slots and handle them manually -->
                <div slot="spinner"></div>
                <span slot="no-more"></span>
                <!-- TODO: implement error handling -->
                <span slot="error"></span>
            </infinite-loading>

            <v-fab-transition>
                <v-btn fab bottom right fixed color="accent" @click="openNewOrderDialog">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>
        </v-container>

        <v-dialog v-model="newOrderDialog.show" max-width="600">
            <v-card>
                <v-card-title class="primary white--text">
                    {{ $t("view.orders.newOrderDialog.header") }}
                </v-card-title>

                <v-form ref="newOrderForm" v-model="newOrderDialog.valid" @submit.prevent="createNewOrder">
                    <v-card-text>
                        <v-select :label="$t('common.label.shopType')"
                                  :disabled="newOrderDialog.loading"
                                  v-model="newOrderDialog.shopType"
                                  required
                                  :rules="shopTypeRules"
                                  :items="shopTypes">
                            <template v-slot:item="{ item }">
                                {{ $t("masterData.shopType." + item) }}
                            </template>
                            <template v-slot:selection="{ item }">
                                {{ $t("masterData.shopType." + item) }}
                            </template>
                        </v-select>

                        <v-select :label="$t('common.label.filmType')"
                                  :disabled="newOrderDialog.loading"
                                  v-model="newOrderDialog.filmType"
                                  required
                                  :rules="filmTypeRules"
                                  :items="filmTypes">
                            <template v-slot:item="{ item }">
                                {{ $t("masterData.filmType." + item) }}
                            </template>
                            <template v-slot:selection="{ item }">
                                {{ $t("masterData.filmType." + item) }}
                            </template>
                        </v-select>

                        <v-text-field
                                v-model="newOrderDialog.shopNumber"
                                :counter="20"
                                :rules="shopNumberRules"
                                :label="$t('common.label.shopNumber')"
                                :disabled="newOrderDialog.loading"
                                required
                        ></v-text-field>

                        <v-text-field
                                v-model="newOrderDialog.orderNumber"
                                :counter="20"
                                :rules="orderNumberRules"
                                :label="$t('common.label.orderNumber')"
                                :disabled="newOrderDialog.loading"
                                required
                        ></v-text-field>

                        <v-text-field
                                v-model="newOrderDialog.description"
                                :counter="30"
                                :rules="descriptionRules"
                                :label="$t('common.label.description')"
                                :disabled="newOrderDialog.loading"
                        ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text :loading="newOrderDialog.loading"
                               :disabled="newOrderDialog.loading || !newOrderDialog.valid"
                               type="submit">
                            {{ $t("common.button.submit") }}
                        </v-btn>
                        <v-btn text @click="newOrderDialog.show = false" :disabled="newOrderDialog.loading">
                            {{ $t("common.button.cancel") }}
                        </v-btn>
                    </v-card-actions>
                </v-form>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import OrderService from "@/services/web/OrderService";
import InfiniteLoading from "vue-infinite-loading";
import {
    ARCHIVE_FILTER,
    FILM_TYPES,
    GENERIC_FILTER_ALL,
    NETWORK_ERROR,
    SHOP_TYPES,
    STATE_TO_ICON
} from "@/services/Constants";
import NotificationUtils from "@/services/util/NotificationUtils";
import Utils from "@/services/util/CommonUtils";
import CommonUtils from "@/services/util/CommonUtils";

const DEFAULT_PAGE_SIZE = 10

export default {
    name: "OrderView",
    components: {
        InfiniteLoading
    },
    data: () => ({
        currentPage: 0,
        loading: false,
        orders: [],
        newOrderDialog: {
            show: false,
            loading: false,
            error: false,
            valid: false,
            shopType: "",
            shopNumber: "",
            orderNumber: "",
            filmType: "",
            description: "",
        }
    }),
    methods: {
        formatCurrency: CommonUtils.formatCurrency,
        renderEventDate: function (eventDate) {
            return Utils.formatDate(eventDate)
        },
        mapStateToIcon: function (state) {
            let icon = STATE_TO_ICON[state]
            if (!icon) {
                icon = "";
            }
            return icon
        },
        loadOrders: function ($state) {
            console.log("Loading orders...")
            this.$store.commit("startGlobalLoading")
            OrderService.loadOrders(this.currentPage, DEFAULT_PAGE_SIZE)
                .then(response => {
                    this.$store.commit("stopGlobalLoading")
                    if (response.data !== undefined && response.data.length > 0) {
                        this.orders.push(...response.data)
                        this.currentPage++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                }).catch(error => {
                    this.$store.commit("stopGlobalLoading")
                    $state.error()
                    this.handleLoadError(error)
                }
            )
        },
        handleLoadError: function (error) {
            if (error.message === NETWORK_ERROR) {
                NotificationUtils.showErrorNotification('common.error.network')
            } else if (error.response) {
                switch (error.response.status) {
                    case 409:
                        NotificationUtils.showErrorNotification('view.orders.newOrderDialog.error.duplicate')
                        break;
                    default:
                        NotificationUtils.showErrorNotification("common.error.unexpectedStatus", {status: error.response.status});
                        break;
                }
            } else {
                NotificationUtils.showErrorNotification('common.error.unexpected')
            }
        },
        isFilteredItem: function (order) {
            const filterMode = this.$store.getters.currentRouteArchiveFilter
            return !(filterMode === GENERIC_FILTER_ALL || (filterMode === ARCHIVE_FILTER.ACTIVE_ONLY && !order.archived) || (filterMode === ARCHIVE_FILTER.ARCHIVED && order.archived))
        },
        openOrderDetails: function (order) {
            this.$store.commit("setOpenOrder", order)
            this.$router.push({name: "orderDetail"})
        },
        openNewOrderDialog: function () {
            if (this.$refs.newOrderForm !== undefined) {
                this.$refs.newOrderForm.reset()
            }

            this.newOrderDialog.shopType = this.shopTypes[0]
            this.newOrderDialog.filmType = this.filmTypes[0]
            this.newOrderDialog.orderNumber = ""
            this.newOrderDialog.shopNumber = ""
            this.newOrderDialog.description = ""

            this.newOrderDialog.loading = false
            this.newOrderDialog.valid = false
            this.newOrderDialog.error = false
            this.newOrderDialog.show = true
        },
        createNewOrder: function () {
            console.log("Creating new order")
            this.newOrderDialog.loading = true

            OrderService.newOrder(this.newOrderDialog.shopType, this.newOrderDialog.shopNumber, this.newOrderDialog.orderNumber, this.newOrderDialog.filmType, this.newOrderDialog.description)
                .then(response => {
                    let newOrder = response.data
                    this.orders.unshift(newOrder)
                    this.newOrderDialog.loading = false
                    this.newOrderDialog.show = false
                    NotificationUtils.showSuccessNotification("view.orders.newOrderDialog.success")
                })
                .catch(error => {
                    if (error.message === NETWORK_ERROR) {
                        NotificationUtils.showErrorNotification('common.error.network')
                    } else if (error.response) {
                        switch (error.response.status) {
                            case 409:
                                NotificationUtils.showErrorNotification('view.orders.newOrderDialog.error.duplicate')
                                break;
                            default:
                                NotificationUtils.showErrorNotification("common.error.unexpectedStatus", {status: error.response.status});
                                break;
                        }
                    } else {
                        NotificationUtils.showErrorNotification('common.error.unexpected')
                    }
                    this.newOrderDialog.error = true
                    this.newOrderDialog.loading = false
                })
        },
    },
    computed: {
        shopTypes: function () {
            return SHOP_TYPES
        },
        filmTypes: function () {
            const value = []
            for (const f in FILM_TYPES) {
                value.push(f)
            }
            return value
        },
        shopTypeRules() {
            return [
                v => !!v || this.$t("view.orders.newOrderDialog.validation.shopType.required"),
            ]
        },
        filmTypeRules() {
            return [
                v => !!v || this.$t("view.orders.newOrderDialog.validation.filmType.required"),
            ]
        },
        shopNumberRules() {
            return [
                v => !!v || this.$t("view.orders.newOrderDialog.validation.shopNumber.required"),
                // This rule is only valid for DM_GERMANY - must be adapted if there are multiple backend systems
                v => (v && v.length === 4 && !isNaN(v)) || this.$tc("view.orders.newOrderDialog.validation.shopNumber.length", 4),
            ]
        },
        orderNumberRules() {
            return [
                v => !!v || this.$t("view.orders.newOrderDialog.validation.orderNumber.required"),
                // This rule is only valid for DM_GERMANY - must be adapted if there are multiple backend systems
                v => (v && v.length === 6 && !isNaN(v)) || this.$tc("view.orders.newOrderDialog.validation.orderNumber.length", 6),
            ]
        },
        descriptionRules() {
            return [
                v => (!v || (v && v.length <= 30)) || this.$tc("view.orders.newOrderDialog.validation.description.length", 30),
            ]
        },
    }
}

</script>
