import axios from 'axios'
import CommonUtils from "@/services/util/CommonUtils";
import store from "@/store";

const _base = CommonUtils.toAbsoluteUrl(process.env.VUE_APP_API_BASE_URL)

const defaultResponseInterceptor = function (response) {
    return response
}

const injectAuthRequestInterceptor = function (config) {
    if (store.state.auth.token) {
        config.headers.Authorization = "Basic " + btoa("fct:" + store.state.auth.token)
    }
    return config
}

const unauthenticatedResponseInterceptor = function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
        console.warn("Invalid access token")
        store.commit("setSessionAvailable", false)
        store.commit("setAuthToken", undefined)
    } else {
        return Promise.reject(error)
    }
}

console.debug("Base URL is set to " + _base)

const API = axios.create({
    baseURL: _base
})

API.interceptors.request.use(injectAuthRequestInterceptor);
API.interceptors.response.use(defaultResponseInterceptor, unauthenticatedResponseInterceptor);

export default API;

export const baseUrl = _base