<template>
  <div>
    <v-system-bar app color="error" class="text-center" v-if="$store.state.view.syncError.acknowledged">
      <v-spacer/>
      <span><v-icon>mdi-alert</v-icon>{{ $t("view.syncError.systemMessage") }}<v-icon>mdi-alert</v-icon></span>
      <v-spacer/>
    </v-system-bar>
    <v-app-bar dark app color="primary" fade-img-on-scroll :elevation="appBarElevation">
      <v-btn icon v-if="!isHomeView" @click="navigateBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-menu v-if="$route.meta.showFilterIcon" bottom right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </template>

        <v-list class="mt-0" v-if="$route.meta.supportsArchivedFilter">
          <v-radio-group value="" class="mt-0" mandatory hide-details v-model="currentArchiveFilterOption">
            <template v-for="option in archiveFilterOptions">
              <v-list-item>
                <v-list-item-action>
                  <v-radio :value="option.value" :key="option.value">
                    <template v-slot:label>
                    <span class="ml-6">
                      {{ $t('common.filtering.archiveFilterOptions.' + option.label) }}
                    </span>
                    </template>
                  </v-radio>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-radio-group>
        </v-list>

        <v-list class="mt-0" v-if="$route.meta.supportsFilmFilter" subheader>
          <v-subheader dense>{{ $t("common.label.filmFormat") }}</v-subheader>
          <v-radio-group value="" class="mt-0" mandatory hide-details v-model="currentFilmFormatFilterOption">
            <template v-for="option in filmFormatFilterOptions">
              <v-list-item>
                <v-list-item-action>
                  <v-radio :value="option.value" :key="option.value">
                    <template v-slot:label>
                    <span class="ml-6">
                      {{ $t('common.filtering.filmFormatFilterOptions.' + option.label) }}
                    </span>
                    </template>
                  </v-radio>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-radio-group>
          <v-subheader>{{ $t("common.label.filmType") }}</v-subheader>
          <v-radio-group value="" class="mt-0" mandatory hide-details v-model="currentFilmTypeFilterOption">
            <template v-for="option in filmTypeFilterOptions">
              <v-list-item>
                <v-list-item-action>
                  <v-radio :value="option.value" :key="option.value">
                    <template v-slot:label>
                    <span class="ml-6">
                      {{ $t('common.filtering.filmTypeFilterOptions.' + option.label) }}
                    </span>
                    </template>
                  </v-radio>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-radio-group>
        </v-list>
      </v-menu>

      <v-menu bottom right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              v-for="(item, i) in items"
              :key="i" @click="item.onClick">
            <v-list-item-title>{{ $t("view.menu." + item.i18nKey) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-progress-linear color="accent" indeterminate :active="$store.state.view.loading > 0" absolute bottom/>

      <AboutDialog v-model="showAboutDialog"/>
    </v-app-bar>
  </div>
</template>

<script>
import {
  ARCHIVE_FILTER,
  DEFAULT_APPBAR_ELEVATION,
  FILM_FORMATS,
  FILM_TYPES,
  GENERIC_FILTER_ALL
} from "@/services/Constants";
import AboutDialog from "@/components/dialog/AboutDialog.vue";

export default {
  name: "TopBar",
  components: {AboutDialog},
  data: () => ({
    showAboutDialog: false,

    archiveFilterOptions: [{
      label: "active",
      value: ARCHIVE_FILTER.ACTIVE_ONLY
    }, {
      label: "archived",
      value: ARCHIVE_FILTER.ARCHIVED
    }, {
      label: "all",
      value: GENERIC_FILTER_ALL
    }],
    filmFormatFilterOptions: [{
      label: "all",
      value: GENERIC_FILTER_ALL
    }, {
      label: FILM_FORMATS.FILM_135,
      value: FILM_FORMATS.FILM_135
    }, {
      label: FILM_FORMATS.FILM_120,
      value: FILM_FORMATS.FILM_120
    }],
    filmTypeFilterOptions: [{
      label: "all",
      value: GENERIC_FILTER_ALL
    }, {
      label: FILM_TYPES.COLOR_NEGATIVE,
      value: FILM_TYPES.COLOR_NEGATIVE
    }, {
      label: FILM_TYPES.COLOR_POSITIVE,
      value: FILM_TYPES.COLOR_POSITIVE
    }, {
      label: FILM_TYPES.BLACK_WHITE,
      value: FILM_TYPES.BLACK_WHITE
    }]
  }),
  methods: {
    navigateBack: function () {
      this.$router.go(-1)
    },
    openAboutDialog() {
      this.showAboutDialog = true
    }
  },
  computed: {
    isHomeView: function () {
      return this.$route.name === "home";
    },
    items: function () {
      return [
        {
          i18nKey: "settings",
          onClick: () => {
            this.$router.push({name: "settings"})
          }
        },
        {
          i18nKey: "about",
          onClick: this.openAboutDialog
        }]
    },
    currentArchiveFilterOption: {
      set(value) {
        this.$store.commit("setCurrentRouteArchiveFilter", value)
      },
      get() {
        return this.$store.getters.currentRouteArchiveFilter
      }
    },
    currentFilmFormatFilterOption: {
      set(value) {
        this.$store.commit("setCurrentFilmFormatFilter", value)
      },
      get() {
        return this.$store.getters.currentFilmFormatFilter
      }
    },
    currentFilmTypeFilterOption: {
      set(value) {
        this.$store.commit("setCurrentFilmTypeFilter", value)
      },
      get() {
        return this.$store.getters.currentFilmTypeFilter
      }
    },
    appBarElevation: function () {
      // Determine whether the appbar elevation should be visible or not. This can be used to increase the seen appbar size with an additional card component
      let renderElevation = !this.$route.meta.dontRenderElevation
      return renderElevation ? DEFAULT_APPBAR_ELEVATION : 0
    }
  }
}
</script>

<style scoped>

</style>