import localforage from "localforage";

export function handleGenericPersistenceError(error) {
  console.error(error)
}

export const StorageKeys = {
  SYNC_QUEUE: "sync.queue",
  INVENTORY_ITEMS: "inventory.items",
  FILM_STOCKS: "masterData.filmStocks",
  LAST_SYNC_FILM_STOCKS: "lastSync.filmStocks",
  SESSION_DATA: "session"
}

/**
 * Reset all storage keys that contain data which is loaded from remote. User data should not be removed using this method.
 */
export async function resetRemoteDataStorage() {
  await localforage.removeItem(StorageKeys.SYNC_QUEUE)
  await localforage.removeItem(StorageKeys.INVENTORY_ITEMS)
  await localforage.removeItem(StorageKeys.FILM_STOCKS)
  await localforage.removeItem(StorageKeys.LAST_SYNC_FILM_STOCKS)
}


