import {toNumber} from "lodash";

export class InventoryItem {
  id;
  stockId;
  format;
  capacity;
  freezerCount;
  fridgeCount;
  bagCount;
  version;
  updatePending;
  constructor(id, stockId, format, capacity, freezerCount, fridgeCount, bagCount, version, updatePending) {
    this.id = id;
    this.format = format;
    this.capacity = capacity;
    this.freezerCount = toNumber(freezerCount);
    this.fridgeCount = toNumber(fridgeCount);
    this.bagCount = toNumber(bagCount);
    this.stockId = stockId;
    this.version = version;
    this.updatePending = updatePending
  }

  static fromObject({id, stockId, format, capacity, freezerCount, fridgeCount, bagCount, version, updatePending}) {
    return new InventoryItem(id, stockId, format, capacity, freezerCount, fridgeCount, bagCount, version, updatePending)
  }
}