import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VTextField,{attrs:{"append-icon":"mdi-menu-down","value":_vm.selectedFilmStockName,"readonly":"","hide-details":"","label":_vm.$t('common.component.filmStockSelector.textField.label'),"placeholder":_vm.$t('common.component.filmStockSelector.textField.placeholder')},on:{"click:append":function($event){_vm.dialogOpen = true}}}),_c(VDialog,{attrs:{"width":"400px"},model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary white--text"},[_vm._v("Select a film stock")]),_c(VList,_vm._l((_vm.$store.state.masterData.filmStocks.groupedData),function(item,i){return _c(VListGroup,{key:i,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v(_vm._s(item.name))])]},proxy:true}],null,true)},_vm._l((item.stocks),function(stock){return _c(VListItem,{key:stock.id,attrs:{"two-line":""},on:{"click":function($event){return _vm.selectStock(stock)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(stock.name)+" ")]),_c(VListItemSubtitle,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-film")]),_vm._v(" "+_vm._s(_vm.$t("masterData.filmType." + stock.type))+" ")],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-speedometer")]),_vm._v(" ISO "+_vm._s(stock.iso)+" ")],1)],1)],1)],1)],1)}),1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }