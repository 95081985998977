import {registerSyncHandler, SyncActions} from "@/services/storage/SyncService";
import InventoryItemService from "@/services/web/InventoryItemService";
import store from "@/store";
import {ErrorTypes, SyncConflictError, SyncError, SyncNetworkError} from "@/services/storage/SyncError";
import {NETWORK_ERROR} from "@/services/Constants";
import LocalInventoryService from "@/services/app/LocalInventoryService";

export const SyncObjectTypes = {
  INVENTORY_ITEM: "inventoryItem"
}

export function registerSyncHandlers() {
  registerSyncHandler(SyncActions.NEW_INVENTORY_ITEM, handleNewInventoryItem)
  registerSyncHandler(SyncActions.UPDATE_INVENTORY_ITEM, handleUpdateInventoryItem)
}

/**
 * Create a new inventory item on the remote backend.
 * @param actionKey {String}
 * @param item {InventoryItem}
 * @returns {Promise<void>}
 */
async function handleNewInventoryItem(actionKey, item) {
  const response = await InventoryItemService.createNewItem(item)
  const data = response.data
  return store.dispatch("setInventoryItemVersion", {itemId: data.id, newVersion: data.version})
}

/**
 * Updates an inventory item on the remote backend.
 * @param actionKey {String}
 * @param item {InventoryItem}
 * @returns {Promise<void>}
 */
async function handleUpdateInventoryItem(actionKey, item) {
  const objectId = item.id
  try {
    const response = await InventoryItemService.updateItem(item.id, item.version, item)
    const data = response.data
    await store.dispatch("setInventoryItemVersion", {itemId: data.id, newVersion: data.version})
  } catch (e) {
    if (e.message === NETWORK_ERROR) {
      throw new SyncNetworkError(e, actionKey, SyncObjectTypes.INVENTORY_ITEM, objectId)
    } else if (e.response) {
      switch (e.response.status) {
        case 409:
          console.warn("Local inventory item with id", item.id, "was changed on remote - local item state will be discarded")
          await LocalInventoryService.forceReloadSingleItem(item.id)
          throw new SyncConflictError(e, actionKey, SyncObjectTypes.INVENTORY_ITEM, objectId, ErrorTypes.UNEXPECTED_STATUS, "common.error.unexpectedStatus", {status: e.response.status})
        default:
          throw new SyncError(e, actionKey, SyncObjectTypes.INVENTORY_ITEM, objectId, ErrorTypes.UNEXPECTED_STATUS, "common.error.unexpectedStatus", {status: e.response.status})
      }
    } else {
      throw new SyncError(e, actionKey, SyncObjectTypes.INVENTORY_ITEM, objectId, ErrorTypes.UNEXPECTED, "common.error.unexpected", undefined)
    }
  }
}