import localforage from "localforage";
import {handleGenericPersistenceError, StorageKeys} from "@/services/storage/Persistence";
import {queueActionForSync, SyncActions} from "@/services/storage/SyncService";
import {SyncObjectTypes} from "@/services/storage/SyncHandlers";

export default {

  newInventoryItem: function (newItem, queueForSync) {
    return localforage.getItem(StorageKeys.INVENTORY_ITEMS).then(items => {
      if (!items) {
        items = [];
      }
      items.push(newItem)
      localforage.setItem(StorageKeys.INVENTORY_ITEMS, items)
        .then(r => {
          console.debug("Added new inventory item to persistent storage")
          if (queueForSync) {
            queueActionForSync(SyncActions.NEW_INVENTORY_ITEM, SyncObjectTypes.INVENTORY_ITEM, newItem.id, newItem)
          }
        })
        .catch(handleGenericPersistenceError)
    })
      .catch(handleGenericPersistenceError)
  },

  updateInventoryItem(updatedItem, queueForSync) {
    return localforage.getItem(StorageKeys.INVENTORY_ITEMS).then(items => {
      if (!items) {
        console.warn("updateInventoryItem was called without locally stored objects")
        return
      }
      let updated = false
      for (let item of items) {
        if (item.id === updatedItem.id) {
          item.format = updatedItem.format
          item.stockId = updatedItem.stockId
          item.capacity = updatedItem.capacity
          item.freezerCount = updatedItem.freezerCount
          item.fridgeCount = updatedItem.fridgeCount
          item.bagCount = updatedItem.bagCount
          item.version = updatedItem.version
          item.updatePending = updatedItem.updatePending
          updated = true
          break
        }
      }

      if (updated) {
        localforage.setItem(StorageKeys.INVENTORY_ITEMS, items)
          .then(r => {
            console.debug("Updated inventory item " + updatedItem.id + " in persistent storage")
            if (queueForSync) {
              queueActionForSync(SyncActions.UPDATE_INVENTORY_ITEM, SyncObjectTypes.INVENTORY_ITEM, updatedItem.id, updatedItem)
            }
          })
          .catch(handleGenericPersistenceError)
      } else {
        console.warn("Failed to find inventory item " + updatedItem.id + " in local storage")
      }
    }).catch(handleGenericPersistenceError)
  },

  setInventoryItemVersion(itemId, newVersion) {
    return localforage.getItem(StorageKeys.INVENTORY_ITEMS).then(items => {
      if (!items) {
        console.warn("setInventoryItemVersion was called without locally stored objects")
        return
      }
      let updated = false
      for (let item of items) {
        if (item.id === itemId) {
          item.version = newVersion
          item.updatePending = false
          updated = true
          break
        }
      }

      if (updated) {
        localforage.setItem(StorageKeys.INVENTORY_ITEMS, items)
          .then(r => {
            console.debug("Updated inventory item version" + itemId + " in persistent storage")
          })
          .catch(handleGenericPersistenceError)
      } else {
        console.warn("Failed to find inventory item " + itemId + " in local storage")
      }
    }).catch(handleGenericPersistenceError)
  }
}