import HTTP from "@/services/util/HTTP";

export default {

    /**
     * Loads the latest version information from the backend.
     */
    fetchVersionInformation() {
        return HTTP.get("/about")
    }
}