<script src="../../services/app/MasterDataService.js"></script>
<template>
  <v-dialog max-width="600px" persistent v-model="show">
    <v-card>
      <v-form ref="passwordForm" v-model="valid" @submit.prevent="setRemotePassword">
        <v-card-text class="text-center">
          <p class="pt-4">
            <v-img :src="require('../../assets/logo.svg')" contain max-width="500px" max-height="400px"/>
          </p>

          <p class="text-h6">{{ $t("view.setPassword.text") }}</p>

          <v-text-field
              v-model="password1"
              type="password"
              :counter="50"
              :rules="passwordRules"
              :label="$t('view.setPassword.label.password')"
              :disabled="loading"
              required
          ></v-text-field>

          <v-text-field
              v-model="password2"
              type="password"
              :counter="50"
              :rules="repeatPasswordRules"
              :label="$t('view.setPassword.label.repeatPassword')"
              :disabled="loading"
              required
          ></v-text-field>

        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn text type="submit" :disabled="!valid || loading" :loading="loading">{{
              $t("common.button.submit")
            }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import NotificationUtils from "@/services/util/NotificationUtils";
import AuthenticationService from "@/services/app/AuthenticationService";
import {AUTHENTICATION_STATE} from "@/services/Constants";
import MasterDataService from "@/services/app/MasterDataService";

export default {
  name: "SetPasswordDialog",
  data() {
    return {
      show: true,
      loading: false,
      valid: false,

      password1: undefined,
      password2: undefined,
    }
  },
  methods: {
    setRemotePassword: function () {
      this.loading = true
      this.error = false
      AuthenticationService.setRemotePassword(this.password1)
          .then(async response => {
            let authState = await AuthenticationService.checkAuthState()
            if (authState === AUTHENTICATION_STATE.NOT_AUTHENTICATED || authState === AUTHENTICATION_STATE.AUTHENTICATED) {
              NotificationUtils.showSuccessNotification("view.setPassword.success")
              if (authState === AUTHENTICATION_STATE.NOT_AUTHENTICATED) {
                AuthenticationService.login(this.password1)
                    .then(async response => {
                      let authState = await AuthenticationService.checkAuthState()
                      this.loading = false
                      if (authState === AUTHENTICATION_STATE.AUTHENTICATED) {
                        this.show = false
                        return MasterDataService.initAll()
                      } else {
                        console.error("Authentication state after login is in unexpected state", authState)
                        NotificationUtils.showErrorNotification("common.error.unexpectedStatus")
                      }
                    }).catch(err => {
                  console.error(err)
                  NotificationUtils.showErrorNotification("common.error.unexpectedStatus", {status: err.response.status})
                  this.loading = false
                })
              }
            } else {
              console.error("Authentication state after password change is in unexpected state", authState)
              NotificationUtils.showErrorNotification("common.error.unexpected")
              this.loading = false
            }
          })
          .catch(error => {
            console.error(error)
            this.loading = false
            NotificationUtils.showErrorNotification("common.error.unexpected")
          })
    },
  },
  computed: {
    passwordRules() {
      return [
        v => !!v || this.$t("view.setPassword.validation.password.required"),
        v => (!v || (v && v.length <= 50)) || this.$tc("view.setPassword.validation.password.maxLength", 50),
        v => (!v || (v && v.length >= 6)) || this.$tc("view.setPassword.validation.password.minLength", 6),
      ]
    },
    repeatPasswordRules() {
      return [
        v => v === this.password1 || this.$t("view.setPassword.validation.repeatPassword.equal"),
      ]
    }
  }
}

</script>