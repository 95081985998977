import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"450px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardText,{staticClass:"text-center"},[_c('p',{staticClass:"pt-4"},[_c(VImg,{attrs:{"src":require('../../assets/logo.svg'),"contain":"","max-width":"500px","max-height":"400px"}})],1),(!_vm.loading)?_c('div',[(!_vm.error)?_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"text-left",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.$t("view.about.startup")))]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.startup))])],1),_c(VRow,[_c(VCol,{staticClass:"text-left",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.$t("view.about.revision")))]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.revision))])],1),_c(VRow,[_c(VCol,{staticClass:"text-left",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.$t("view.about.buildTime")))]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.buildTimestamp))])],1),_c(VRow,{staticClass:"mt-2"},[_c(VCol,[_c('a',{attrs:{"href":"/rapidoc"}},[_vm._v("API documentation")])])],1)],1):_c(VRow,{staticClass:"text-center"},[_c(VCol,[_vm._v(" "+_vm._s(_vm.$t("view.about.serverNotAvailable"))+" ")])],1)],1):_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }