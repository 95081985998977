<template>
  <v-dialog width="450px" v-model="updateExists" persistent>
    <v-card>
      <v-card-text class="text-center">
        <p class="pt-4">
          <v-icon size="100px" color="primary">mdi-update</v-icon>
        </p>

        <p class="text-h4">{{ $t("view.update.text") }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="refreshApp" :loading="refreshing">{{ $t("view.update.reload") }}</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "UpdateDialog",
  data() {
    return {
      // refresh variables
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  created() {
    document.addEventListener("pwaUpdated", this.updateAvailable, {once: true})

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) {
        return
      }
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },
  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },
    // Called when the user accepts the update
    refreshApp() {
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({type: 'SKIP_WAITING'})
    },
  }
}

</script>