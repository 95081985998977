<template>
    <v-snackbar v-model="show" :timeout="timeout" bottom :color="color" v-if="messageKey">
        {{ $t(messageKey, params) }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="show = false" small>
                {{ $t("common.button.close") }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import {registerNotificationHandler, unregisterNotificationHandler} from "@/services/util/NotificationUtils";

const DEFAULT_TIMEOUT = 4000

export default {
    name: "Notification",
    data: () => ({
        messageKey: undefined,
        params: undefined,
        show: false,
        timeout: -1,
        color: "primary"
    }),
    methods: {
        showMessage: function (messageKey, params, type) {
            this.messageKey = messageKey
            this.show = true
            this.params = params
            if (type === "error") {
                this.color = "error"
                this.timeout = 1.5 * DEFAULT_TIMEOUT
            } else if (type === "success") {
                this.color = "success"
                this.timeout = DEFAULT_TIMEOUT
            } else {
                this.color = "primary"
                this.timeout = DEFAULT_TIMEOUT
            }
        }
    },
    mounted() {
        registerNotificationHandler(this.showMessage)
    },
    beforeDestroy() {
        unregisterNotificationHandler()
    }
}
</script>

<style scoped>

</style>