<template>
  <v-dialog width="450px" v-model="show">
    <v-card>
      <v-card-text class="text-center">
        <p class="pt-4">
          <v-img :src="require('../../assets/logo.svg')" contain max-width="500px" max-height="400px"/>
        </p>

        <div v-if="!loading">
          <v-container v-if="!error">
            <v-row>
              <v-col cols="6" class="text-left">{{ $t("view.about.startup") }}</v-col>
              <v-col cols="6" class="text-right">{{ startup }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="text-left">{{ $t("view.about.revision") }}</v-col>
              <v-col cols="6" class="text-right">{{ revision }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="text-left">{{ $t("view.about.buildTime") }}</v-col>
              <v-col cols="6" class="text-right">{{ buildTimestamp }}</v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col>
                <a href="/rapidoc">API documentation</a>
              </v-col>
            </v-row>
          </v-container>
          <v-row class="text-center" v-else>
            <v-col>
              {{ $t("view.about.serverNotAvailable") }}
            </v-col>
          </v-row>
        </div>
        <v-row v-else>
          <v-col cols="12">
            <v-progress-circular indeterminate color="primary"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AboutService from "@/services/web/AboutService";
import CommonUtils from "@/services/util/CommonUtils";

export default {
  name: "AboutDialog",
  data: () => ({
    revision: undefined,
    buildTimestamp: undefined,
    startup: undefined,
    loading: false,
    error: false
  }),
  methods: {
    fetchVersionInformation() {
      this.loading = true;
      AboutService.fetchVersionInformation()
          .then(response => {
            this.startup = CommonUtils.formatDateTime(response.data.startup)
            this.buildTimestamp = response.data.buildTimestamp
            this.revision = response.data.revision
            this.loading = false
          })
          .catch(error => {
            console.error(error)
            this.error = true
            this.loading = false
          })
    },
  },
  props: {
    value: Boolean
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    show: function(value) {
      if (value) {
        this.fetchVersionInformation()
      }
    }
  }
}
</script>

<style scoped>

</style>