import HTTP from "@/services/util/HTTP";

export default {

  sendTestNotification() {
    return HTTP.post("/configuration/sendTestNotification")
  },

  loadCurrentConfiguration() {
    return HTTP.get("/configuration")
  }
}