import HTTP from "@/services/util/HTTP";

export default {
    
    loadOrders(page, size) {
        return HTTP.get("/orders", {
            params: {
                page,
                size
            }
        })
    },

    newOrder(shop, shopNumber, orderNumber, filmType, description) {
        return HTTP.post("/orders", {
            shop: shop,
            shopNumber: shopNumber,
            orderNumber: orderNumber,
            filmType: filmType,
            description: description
        })
    },

    archiveOrder(orderId) {
        return HTTP.post("/orders/" + orderId + "/archive")
    },

    unarchiveOrder(orderId) {
        return HTTP.post("/orders/" + orderId + "/unarchive")
    },

    pickUpOrder(orderId) {
        return HTTP.post("/orders/" + orderId + "/pickUp")
    },

    refreshOrder(orderId) {
        return HTTP.post("/orders/" + orderId + "/refresh")
    },

    deleteOrder(orderId) {
        return HTTP.delete("/orders/" + orderId)
    }
}