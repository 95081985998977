import store from "@/store";
import {InventoryItem} from "@/store/types";
import localforage from "localforage";
import {handleGenericPersistenceError, StorageKeys} from "@/services/storage/Persistence";
import CommonUtils from "@/services/util/CommonUtils";
import InventoryItemService from "@/services/web/InventoryItemService";
import storageHelper from "@/store/storageHelper";
import NotificationUtils from "@/services/util/NotificationUtils";
import {i18n} from "@/plugins/i18n";
import {SyncObjectTypes} from "@/services/storage/SyncHandlers";

async function performFullInventoryItemSync() {
  console.debug("Starting full inventory item sync")
  NotificationUtils.startGlobalLoading()
  let fetchResponse
  try {
    fetchResponse = await InventoryItemService.fetchAll()
  } catch (err) {
    console.error("Error starting full inventory item sync")
    NotificationUtils.stopGlobalLoading()
    throw err   // FIXME: Implement offline handling
  }
  try {
    const allRemoteItems = fetchResponse.data

    // Prepare internal data structures
    const allRemoteItemsMap = new Map()
    for (let remoteItem of allRemoteItems) {
      allRemoteItemsMap.set(remoteItem.id, remoteItem)
    }
    const allLocalItemsMap = new Map()
    for (let localItem of store.state.inventory.items) {
      allLocalItemsMap.set(localItem.id, localItem)
    }

    //  Update all data from remote
    for (let remoteItem of allRemoteItems) {
      if (allLocalItemsMap.has(remoteItem.id)) {
        const localItem = allLocalItemsMap.get(remoteItem.id)
        if (!localItem.updatePending && localItem.version !== remoteItem.version) {
          console.debug("Updated inventory item", remoteItem.id, "from remote")
          store.commit("updateInventoryItem", remoteItem)
          await storageHelper.updateInventoryItem(remoteItem, false)
        } else if (localItem.updatePending && localItem.version !== remoteItem.version) {
          // Conflict -> local and remote item were modified
          console.warn("Conflicting inventory item ", remoteItem.id, "change from remote")
          NotificationUtils.showErrorNotification("common.error.syncConflict", {syncObjectType: i18n.t("common.syncObjectType." + SyncObjectTypes.INVENTORY_ITEM)})
        }
      } else {
        console.debug("Added new inventory item", remoteItem.id, "from remote")
        store.commit("newInventoryItem", remoteItem)
        await storageHelper.newInventoryItem(remoteItem, false)
      }
    }

    // Check if local items must be removed
    for (let localItem of store.state.inventory.items) {
      if (!allRemoteItemsMap.has(localItem.id)) {
        if (localItem.updatePending) {
          console.warn("Locally modified inventory item ", localItem.id, "was deleted remotely ")
          // FIXME: implement solution / notify user?
        } else {
          store.commit("removeInventoryItem", localItem.id)
          // FIXME: implement this also for localforage
        }
      }
    }
    console.debug("Full inventory item sync finished")
  } finally {
    NotificationUtils.stopGlobalLoading()
  }
}

async function forceReloadSingleItem(itemId) {
  const response = await InventoryItemService.fetchItem(itemId)
  const itemData = response.data
  console.debug("Reloaded inventory item with id " + itemId)
  store.commit("updateInventoryItem", itemData)
  return storageHelper.updateInventoryItem(itemData, false)
}

export default {
  /**
   * Initialize inventory data from persistent storage.
   */
  initializeInventory() {
    return localforage.getItem(StorageKeys.INVENTORY_ITEMS)
      .then(items => {
        const converted = []
        if (items != null) {
          for (let item of items) {
            converted.push(InventoryItem.fromObject(item))
          }
        }
        store.commit("setInventoryItems", converted)
        return performFullInventoryItemSync()
      })
      .catch(handleGenericPersistenceError)
  },
  /**
   * Creates a new {@link InventoryItem} and queues it for syncing.
   *
   * @param stockId
   * @param format
   * @param capacity
   * @param freezerCount
   * @param fridgeCount
   * @param bagCount
   */
  newInventoryItem(stockId, format, capacity, freezerCount, fridgeCount, bagCount) {
    const item = new InventoryItem(CommonUtils.generateUuid(), stockId, format, capacity, freezerCount, fridgeCount, bagCount, 1, true)
    return store.dispatch("newInventoryItem", item)
  },
  updateInventoryItem(existingItemId, stockId, format, capacity, freezerCount, fridgeCount, bagCount, oldVersion) {
    const updatedItem = new InventoryItem(existingItemId, stockId, format, capacity, freezerCount, fridgeCount, bagCount, oldVersion, true)
    return store.dispatch("updateInventoryItem", updatedItem)
  },
  /**
   * Run a full sync with the remote backend. Updates all local items and removes any locals that are not present on remote.
   * If a local item update is still pending while the remote version is the same, no update of the single item will be performed.
   */
  performFullInventoryItemSync,
  /**
   * Force reload a single inventory item from remote. Thus clear any local changes to that object.
   */
  forceReloadSingleItem
}