<template>

</template>

<script>
export default {
  name: "NotFoundView",
  mounted: function() {
    this.$router.push({name: "home"})
  }
}
</script>

<style scoped>

</style>