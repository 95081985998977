import router from "@/router";

export default {
  isOrderSelected: function (state) {
    return state.view.currentOrder !== undefined && state.view.currentOrder.id !== undefined
  },
  currentRouteArchiveFilter: function(state) {
    const currentRoute = router.currentRoute.name
    return state.view.routeSpecific[currentRoute].currentArchiveFilter
  },
  currentRouteFilmFormatFilter: function(state) {
    const currentRoute = router.currentRoute.name
    return state.view.routeSpecific[currentRoute].currentFilmFormatFilter
  },
  currentRouteFilmTypeFilter: function(state) {
    const currentRoute = router.currentRoute.name
    return state.view.routeSpecific[currentRoute].currentFilmTypeFilter
  }
}