import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"600px","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardText,{staticClass:"text-center"},[_c('p',{staticClass:"pt-4"},[_c(VIcon,{attrs:{"size":"100px","color":"primary"}},[_vm._v("mdi-emoticon-dead-outline")])],1),_c('p',{staticClass:"text-h4 text-center"},[_vm._v(_vm._s(_vm.$t('view.syncError.title')))]),(_vm.$store.state.view.syncError.additionalInfo)?_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('view.syncError.additionalInfo'))+" "+_vm._s(_vm.$store.state.view.syncError.additionalInfo))]):_vm._e(),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('view.syncError.helpText')))])]),_c(VCardActions,[_c(VBtn,{staticClass:"ml-4",attrs:{"text":""},on:{"click":function($event){return _vm.restart()}}},[_vm._v(" "+_vm._s(_vm.$t('view.syncError.restart'))+" ")]),_c(VSpacer),_c(VBtn,{staticClass:"ml-4",attrs:{"text":""},on:{"click":function($event){return _vm.acknowledge()}}},[_vm._v(" "+_vm._s(_vm.$t('common.button.cancel'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }