<template>
  <div>
    <v-simple-table fixed-header class="overflow-x-hidden" v-if="sortedAndFilteredItems.length > 0">
      <template v-slot:default>
        <thead>
        <tr>
          <th>

          </th>
          <th class="text-left pl-sm-3 pl-1">
            {{ $t('view.inventory.labels.stock') }}
          </th>
          <th class="text-center pa-1">
            <v-icon>mdi-camera-iris</v-icon>
            <span class="hidden-md-and-down pl-2">{{ $t('view.inventory.labels.format') }}</span>
          </th>
          <th class="text-center pa-1">
            <v-icon>mdi-speedometer</v-icon>
            <span class="hidden-md-and-down pl-2">{{ $t('view.inventory.labels.iso') }}</span>
          </th>
          <th class="text-center pa-1">
            <v-icon>mdi-counter</v-icon>
            <span class="hidden-md-and-down pl-2">{{ $t('view.inventory.labels.capacity') }}</span>
          </th>
          <th class="text-center pa-0">
            <v-icon>mdi-snowflake</v-icon>
            <span class="hidden-md-and-down pl-2">{{ $t('view.inventory.labels.freezer') }}</span>
          </th>
          <th class="text-center pa-0">
            <v-icon>mdi-fridge-outline</v-icon>
            <span class="hidden-md-and-down pl-2">{{ $t('view.inventory.labels.fridge') }}</span>
          </th>
          <th class="text-center pa-0">
            <v-icon>mdi-bag-personal</v-icon>
            <span class="hidden-md-and-down pl-2">{{ $t('view.inventory.labels.bag') }}</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in sortedAndFilteredItems"
            :key="item.id" :class="item.updatePending ? 'font-italic' : ''"
        >
          <td class="pl-sm-3 pl-1 pr-1">
            <v-btn icon small @click="editItem(item)">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </td>
          <td class="pa-0">{{ getNameFromStockId(item.stockId) }}</td>
          <td class="text-center pa-0">{{ $t("masterData.filmFormatShort." + item.format) }}</td>
          <td class="text-center pa-0">{{ getIsoFromStockId(item.stockId) }}</td>
          <td class="text-center pa-0">{{ item.capacity }}</td>
          <td class="text-center pa-0">{{ item.freezerCount }}</td>
          <td class="text-center pa-0">{{ item.fridgeCount }}</td>
          <td class="text-center pa-0">{{ item.bagCount }}</td>
        </tr>

        <tr>
          <td class="pl-sm-3 pl-1 pr-1">
          </td>
          <td class="pa-0">{{ $t("view.inventory.labels.total") }}</td>
          <td class="text-center pa-0"></td>
          <td class="text-center pa-0"></td>
          <td class="text-center pa-0">{{ totalCapacity }}</td>
          <td class="text-center pa-0">{{ totalFreezerCount }}</td>
          <td class="text-center pa-0">{{ totalFridgeCount }}</td>
          <td class="text-center pa-0">{{ totalBagCount }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else class="text-center pl-12 pr-12 pt-12 text-h5">
      {{ $t("view.inventory.noEntries1") }}
      <v-icon>mdi-plus</v-icon>
      {{ $t("view.inventory.noEntries2") }}
    </div>

    <v-fab-transition>
      <v-btn fab bottom right fixed color="accent" @click="openNewItemDialog">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog v-model="itemDialog.show" max-width="600px">
      <v-card>
        <v-card-title class="primary white--text">
          {{
            itemDialog.editMode ? $t("view.inventory.itemDialog.header.edit") : $t("view.inventory.itemDialog.header.new")
          }}
        </v-card-title>

        <v-form ref="itemForm" v-model="itemDialog.valid" @submit.prevent="createOrUpdateItem">
          <v-card-text>
            <v-row v-if="isDuplicateInventoryItem">
              <v-col class="pb-0 pt-1 error--text">{{ $t("view.inventory.itemDialog.duplicateInventoryItem") }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <film-stock-selector v-model="itemDialog.stockId"/>
              </v-col>
            </v-row>
            <v-row v-if="itemDialog.stockId !== undefined" class="mt-0">
              <v-col cols="6">
                <v-icon small>mdi-film</v-icon>
                {{ $t("masterData.filmType." + itemDialogStock.type) }}
              </v-col>
              <v-col cols="6">
                <v-icon small>mdi-speedometer</v-icon>
                {{ $t('view.inventory.labels.iso') }} {{ itemDialogStock.iso }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-select :items="availableFormats" :label="$t('view.inventory.labels.format')"
                          v-model="itemDialog.format" @input="updateCapacityOnFormatChange">
                </v-select>
              </v-col>
              <v-col cols="6" class="mt-2">
                <v-row>
                  <span class="text-caption">{{ $t('view.inventory.labels.capacity') }}</span>
                </v-row>
                <v-row>
                  <v-spacer/>
                  <v-btn-toggle v-model="itemDialog.capacity">
                    <v-btn small value="12">
                      12
                    </v-btn>
                    <v-btn small value="24">
                      24
                    </v-btn>
                    <v-btn small value="36">
                      36
                    </v-btn>
                  </v-btn-toggle>
                  <v-spacer/>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field type="number" prepend-icon="mdi-snowflake" v-model="itemDialog.freezerCount"
                              :label="$t('view.inventory.labels.freezer')" :rules="itemCountRules"/>
              </v-col>
              <v-col cols="4">
                <v-text-field type="number" prepend-icon="mdi-fridge-outline" v-model="itemDialog.fridgeCount"
                              :label="$t('view.inventory.labels.fridge')" :rules="itemCountRules"/>
              </v-col>
              <v-col cols="4">
                <v-text-field type="number" prepend-icon="mdi-bag-personal" v-model="itemDialog.bagCount"
                              :label="$t('view.inventory.labels.bag')" :rules="itemCountRules"/>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer/>
            <v-btn text :disabled="!itemDialog.valid || !requiredValuesSet || isDuplicateInventoryItem" type="submit">
              {{ $t("common.button.save") }}
            </v-btn>
            <v-btn text @click="itemDialog.show = false">
              {{ $t("common.button.cancel") }}
            </v-btn>
          </v-card-actions>

        </v-form>

      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import FilmStockSelector from "@/components/FilmStockSelector.vue";
import {FILM_FORMATS, GENERIC_FILTER_ALL} from "@/services/Constants";
import InventoryService from "@/services/app/LocalInventoryService";
import {sortBy, toNumber} from "lodash";

const MAXIMUM_ITEM_COUNT = 999;

export default {
  name: "InventoryView",
  components: {FilmStockSelector},
  data: () => ({
    itemDialog: {
      show: false,
      editMode: false,
      valid: false,
      selectedItem: undefined,

      /** Selected / new item */
      stockId: undefined,
      format: undefined,
      capacity: undefined,
      freezerCount: 0,
      fridgeCount: 0,
      bagCount: 0
    }
  }),
  computed: {
    sortedAndFilteredItems() {
      console.debug("resorting items")
      const filteredItems = this.$store.state.inventory.items.filter(item => {
        return (this.$store.getters.currentRouteFilmFormatFilter === GENERIC_FILTER_ALL || this.$store.getters.currentRouteFilmFormatFilter === item.format)
              && (this.$store.getters.currentRouteFilmTypeFilter === GENERIC_FILTER_ALL || this.$store.getters.currentRouteFilmTypeFilter === this.getTypeFromStockId(item.stockId))
      })
      return sortBy(filteredItems, item => {
        const stock = this.$store.state.masterData.filmStocks.cache[item.stockId]
        return stock != undefined ? stock.manufacturer + "_" + stock.name + "_" + item.format + item.capacity : "???";
      });
    },
    itemDialogStock() {
      return this.itemDialog.stockId !== undefined ? this.$store.state.masterData.filmStocks.cache[this.itemDialog.stockId] : undefined
    },
    availableFormats() {
      const items = []
      for (let f in FILM_FORMATS) {
        items.push({
          "value": f,
          "text": this.$t("masterData.filmFormat." + f)
        })
      }
      return items
    },
    itemCountRules() {
      return [
        v => (v !== undefined && !isNaN(v)) && v >= 0 && v <= MAXIMUM_ITEM_COUNT || this.$tc("view.inventory.itemDialog.validation.itemCount", MAXIMUM_ITEM_COUNT),
      ]
    },
    requiredValuesSet() {
      return this.itemDialog.stockId !== undefined && this.itemDialog.format !== undefined && this.itemDialog.capacity !== undefined && this.itemDialog.capacity > 0
    },
    isDuplicateInventoryItem() {
      let duplicate = false
      for (let item of this.$store.state.inventory.items) {
        if (item.stockId === this.itemDialog.stockId && item.format === this.itemDialog.format && toNumber(item.capacity) === toNumber(this.itemDialog.capacity)) {
          if (this.itemDialog.editMode && this.itemDialog.selectedItem.id === item.id) {
            continue
          }
          duplicate = true
          break
        }
      }
      return duplicate
    },
    totalCapacity() {
      let totalCapacity = 0
      for(const item of this.sortedAndFilteredItems) {
        totalCapacity += item.capacity * (item.bagCount + item.fridgeCount + item.freezerCount)
      }
      return totalCapacity
    },
    totalBagCount() {
      let totalBagCount = 0
      for(const item of this.sortedAndFilteredItems) {
        totalBagCount += item.bagCount
      }
      return totalBagCount
    },
    totalFridgeCount() {
      let totalFridgeCount = 0
      for(const item of this.sortedAndFilteredItems) {
        totalFridgeCount += item.fridgeCount
      }
      return totalFridgeCount
    },
    totalFreezerCount() {
      let totalFreezerCount = 0
      for(const item of this.sortedAndFilteredItems) {
        totalFreezerCount += item.freezerCount
      }
      return totalFreezerCount
    }
  },
  methods: {
    getNameFromStockId: function (stockId) {
      const stock = this.$store.state.masterData.filmStocks.cache[stockId]
      return stock ? stock.manufacturer + " " + stock.name : "???"
    },
    getTypeFromStockId: function (stockId) {
      const stock = this.$store.state.masterData.filmStocks.cache[stockId]
      return stock ? stock.type : "???"
    },
    getIsoFromStockId: function (stockId) {
      const stock = this.$store.state.masterData.filmStocks.cache[stockId]
      return stock ? stock.iso : "???"
    },
    openNewItemDialog: function () {
      this.itemDialog.stockId = undefined
      this.itemDialog.format = undefined
      this.itemDialog.capacity = undefined
      this.itemDialog.freezerCount = 0
      this.itemDialog.fridgeCount = 0
      this.itemDialog.bagCount = 0

      this.itemDialog.editMode = false
      this.itemDialog.show = true
    },
    editItem: function (item) {
      this.itemDialog.selectedItem = item

      this.itemDialog.stockId = item.stockId
      this.itemDialog.format = item.format
      this.itemDialog.capacity = "" + item.capacity
      this.itemDialog.freezerCount = "" + item.freezerCount
      this.itemDialog.fridgeCount = "" + item.fridgeCount
      this.itemDialog.bagCount = "" + item.bagCount

      this.itemDialog.editMode = true
      this.itemDialog.show = true
    },
    createOrUpdateItem: function () {
      if (this.itemDialog.freezerCount === undefined) {
        this.item.itemDialog.freezerCount = 0
      }
      if (this.itemDialog.fridgeCount === undefined) {
        this.item.itemDialog.fridgeCount = 0
      }
      if (this.itemDialog.bagCount === undefined) {
        this.item.itemDialog.bagCount = 0
      }

      if (this.itemDialog.editMode) {
        InventoryService.updateInventoryItem(this.itemDialog.selectedItem.id, this.itemDialog.stockId,
            this.itemDialog.format, this.itemDialog.capacity, this.itemDialog.freezerCount,
            this.itemDialog.fridgeCount, this.itemDialog.bagCount, this.itemDialog.selectedItem.version)
        this.itemDialog.show = false
      } else {
        InventoryService.newInventoryItem(this.itemDialog.stockId, this.itemDialog.format, this.itemDialog.capacity,
            this.itemDialog.freezerCount, this.itemDialog.fridgeCount, this.itemDialog.bagCount)
        this.itemDialog.show = false
      }
    },
    updateCapacityOnFormatChange() {
      if (this.itemDialog.format === FILM_FORMATS.FILM_135) {
        this.itemDialog.capacity = "36"
      } else if (this.itemDialog.format === FILM_FORMATS.FILM_120) {
        this.itemDialog.capacity = "12"
      }
    }
  }
}
</script>

<style scoped>

</style>