
export const ErrorTypes = {
  NETWORK_ERROR: "networkError",
  UNEXPECTED_STATUS: "unexpectedStatus",
  UNEXPECTED: "unexpected",
  CONFLICT: "conflict"
}

/**
 * Generic error thrown when something goes wrong during synchronization.
 */
export class SyncError {
  messageKey;
  actionKey;

  objectType;

  objectId;

  errorType;

  originalError;

  constructor(originalError, actionKey, objectType, objectId, errorType, messageKey) {
    this.originalError = originalError;
    this.messageKey = messageKey;
    this.actionKey = actionKey;
    this.objectType = objectType;
    this.objectId = objectId;
    this.errorType = errorType;
  }
}

/**
 * Error thrown if a network error occurred while syncing.
 */
export class SyncNetworkError extends SyncError {

  constructor(originalError, actionKey, objectType, objectId) {
    super(originalError, actionKey, objectType, objectId, ErrorTypes.NETWORK_ERROR, "common.error.network");
  }
}

/**
 * Error thrown if a sync conflict occurred.
 */
export class SyncConflictError extends SyncError {

  constructor(originalError, actionKey, objectType, objectId) {
    super(originalError, actionKey, objectType, objectId, ErrorTypes.CONFLICT, "common.error.syncConflict");
  }
}