<template>
  <div v-if="$store.getters.isOrderSelected">

    <v-card color="primary" elevation="4" tile>
      <v-card-title class="text-h5">
        {{
          $t("view.orderDetail.header", {
            shopType: $t("masterData.shopType." + order.shop)
          })
        }}
      </v-card-title>

      <v-card-text class="text--darken-4">
        <v-row align="center">
          <v-col cols="8">
            <v-row class="pb-0 mt-0 font-weight-bold" v-if="order.description">
              <v-col cols="12">{{ order.description }}</v-col>
            </v-row>
            <v-row class="pb-0 mt-0">
              <v-col cols="7">{{ $t("view.orderDetail.labels.shopNumber") }}</v-col>
              <v-col cols="5">{{ order.shopNumber }}</v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="7" class="pt-0">{{ $t("view.orderDetail.labels.orderNumber") }}</v-col>
              <v-col cols="5" class="pt-0">{{ order.orderNumber }}</v-col>
            </v-row>
            <v-row class="mt-0" v-if="order.currentState !== 'DELIVERED' && order.currentState !== 'PICKED_UP' && order.estimatedDeliveryDate">
              <v-col cols="7" class="pt-0">{{ $t("view.orderDetail.labels.estimatedDelivery") }}</v-col>
              <v-col cols="5" class="pt-0">{{ renderEventDate(order.estimatedDeliveryDate) }}</v-col>
            </v-row>
            <v-row class="mt-0" v-if="order.pictureCount">
              <v-col cols="7" class="pt-0">{{ $t("view.orderDetail.labels.pictureCount") }}</v-col>
              <v-col cols="5" class="pt-0">{{ order.pictureCount }}</v-col>
            </v-row>
            <v-row class="mt-0" v-if="order.price">
              <v-col cols="7" class="pt-0">{{ $t("view.orderDetail.labels.price") }}</v-col>
              <v-col cols="5" class="pt-0">{{ formatCurrency(order.price) }} €</v-col>
            </v-row>
          </v-col>
          <v-col class="text-center">
            <v-icon size="80">
              {{ mapStateToIcon(order.currentState) }}
            </v-icon>
          </v-col>
        </v-row>
      </v-card-text>

      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn absolute bottom right fab color="accent" small v-bind="attrs" v-on="on">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list >
          <v-list-item v-if="order.currentState !== 'DELIVERED' && order.currentState !== 'PICKED_UP'" @click="refreshOrder">
            {{  $t("view.orderDetail.actions.refresh") }}
          </v-list-item>
          <v-list-item v-if="order.currentState === 'DELIVERED'" @click="preparePickUpAction">
            {{  $t("view.orderDetail.actions.pickup") }}
          </v-list-item>
          <v-list-item @click="prepareArchiveAction" v-if="!order.archived">
            {{  $t("view.orderDetail.actions.archive") }}
          </v-list-item>
          <v-list-item @click="prepareUnarchiveAction" v-if="order.archived">
            {{  $t("view.orderDetail.actions.unarchive") }}
          </v-list-item>
          <v-list-item @click="prepareDeleteAction">
            {{  $t("view.orderDetail.actions.delete") }}
          </v-list-item>
        </v-list>
      </v-menu>

      <v-progress-linear color="accent" indeterminate :active="refreshing" absolute bottom/>
    </v-card>

    <v-timeline dense>
      <v-timeline-item v-for="event in $store.state.view.currentOrder.orderEvents.slice().reverse()" :key="event.state">
        <v-row class="pt-1" dense>
          <v-col cols="4">
            <strong>{{ renderEventDate(event.eventDate) }}</strong>
          </v-col>
          <v-col cols="6" class="text-uppercase">
            <strong>{{ $t("masterData.orderState." + event.state) }}</strong>
          </v-col>
        </v-row>

        <template v-slot:icon>
          <v-icon>{{ mapStateToIcon(event.state) }}</v-icon>
        </template>
      </v-timeline-item>
    </v-timeline>

    <v-dialog v-model="dialog.show" max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          {{ $t(dialog.title) }}
        </v-card-title>

        <v-card-text class="mt-4">
        {{ $t(dialog.question) }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :loading="dialog.loading" :disabled="dialog.loading"
                 @click="dialog.action">
            {{ $t("common.button.yes") }}
          </v-btn>
          <v-btn text @click="dialog.show = false" :disabled="dialog.loading">
            {{ $t("common.button.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommonUtils from "@/services/util/CommonUtils";
import {NETWORK_ERROR, STATE_TO_ICON} from "@/services/Constants";
import OrderService from "@/services/web/OrderService";
import NotificationUtils from "@/services/util/NotificationUtils";

export default {
  name: "DetailOrderView",
  data: () => ({
    refreshing: false,
    dialog: {
      show: false,
      loading: true,
      title: "",
      question: "",
      action: undefined,
    }
  }),
  mounted() {
    if (!this.$store.getters.isOrderSelected) {
      console.warn("No order selected")
      this.$router.push({name: "home"})
    }
  },
  methods: {
    renderEventDate: function (eventDate) {
      return CommonUtils.formatDate(eventDate)
    },
    mapStateToIcon: function (state) {
      let icon = STATE_TO_ICON[state]
      if (!icon) {
        icon = "";
      }
      return icon
    },
    refreshOrder: function() {
      this.refreshing = true
      let oldState = this.order.currentState
      OrderService.refreshOrder(this.order.id)
          .then(response => {
            let data = response.data
            this.$store.commit("setOpenOrder", data)
            this.refreshing = false
            console.debug("Order is now in state " + data.currentState)
            if (data.currentState === oldState) {
              NotificationUtils.showNotification("view.orderDetail.refreshSameState")
            } else {
              NotificationUtils.showSuccessNotification("view.orderDetail.refreshNewState", {newState: this.$t("masterData.orderState." + this.order.currentState)})
            }
          })
          .catch(this.handleActionError)
    },
    preparePickUpAction: function() {
      this.dialog.loading = false
      this.dialog.title = "view.orderDetail.actions.pickup"
      this.dialog.question = "view.orderDetail.dialogs.pickup"
      this.dialog.action = this.performPickUpAction
      this.dialog.show = true
    },
    prepareArchiveAction: function() {
      this.dialog.loading = false
      this.dialog.title = "view.orderDetail.actions.archive"
      this.dialog.question = "view.orderDetail.dialogs.archive"
      this.dialog.action = this.performArchiveAction
      this.dialog.show = true
    },
    prepareUnarchiveAction: function() {
      this.dialog.loading = false
      this.dialog.title = "view.orderDetail.actions.unarchive"
      this.dialog.question = "view.orderDetail.dialogs.unarchive"
      this.dialog.action = this.performUnarchiveAction
      this.dialog.show = true
    },
    prepareDeleteAction: function() {
      this.dialog.loading = false
      this.dialog.title = "view.orderDetail.actions.delete"
      this.dialog.question = "view.orderDetail.dialogs.delete"
      this.dialog.action = this.performDeleteAction
      this.dialog.show = true
    },
    performPickUpAction: function() {
      this.dialog.loading = true
      OrderService.pickUpOrder(this.order.id)
          .then(response => {
            let data = response.data
            this.$store.commit("setOpenOrder", data)
            this.dialog.loading = false
            this.dialog.show = false
          })
          .catch(this.handleActionError)
    },
    performArchiveAction: function() {
      this.dialog.loading = true
      OrderService.archiveOrder(this.order.id)
          .then(response => {
            let data = response.data
            this.$store.commit("setOpenOrder", data)
            this.dialog.loading = false
            this.dialog.show = false
          })
          .catch(this.handleActionError)
    },
    performUnarchiveAction: function() {
      this.dialog.loading = true
      OrderService.unarchiveOrder(this.order.id)
          .then(response => {
            let data = response.data
            this.$store.commit("setOpenOrder", data)
            this.dialog.loading = false
            this.dialog.show = false
          })
          .catch(this.handleActionError)
    },
    performDeleteAction: function() {
      this.dialog.loading = true
      OrderService.deleteOrder(this.order.id)
          .then(response => {
            this.$router.push({name: "orders"})
            this.$store.commit("setOpenOrder", null)
            this.dialog.loading = false
            this.dialog.show = false
          })
          .catch(this.handleActionError)
    },
    handleActionError: function(error) {
      if (error.message === NETWORK_ERROR) {
        NotificationUtils.showErrorNotification('common.error.network')
      } else if (error.response) {
        switch (error.response.status) {
          default:
            NotificationUtils.showErrorNotification("common.error.unexpectedStatus", {status: error.response.status});
            break;
        }
      } else {
        NotificationUtils.showErrorNotification('common.error.unexpected')
      }
      this.dialog.loading = false
      this.dialog.show = false
      this.refreshing = false
    },
    formatCurrency: CommonUtils.formatCurrency
  },
  computed: {
    order: function () {
      return this.$store.state.view.currentOrder
    }
  }
}
</script>

<style scoped>

</style>