<template>
  <div>
    <v-text-field append-icon="mdi-menu-down" :value="selectedFilmStockName" readonly @click:append="dialogOpen = true" hide-details
                  :label="$t('common.component.filmStockSelector.textField.label')" :placeholder="$t('common.component.filmStockSelector.textField.placeholder')">
    </v-text-field>

    <v-dialog v-model="dialogOpen" width="400px">
      <v-card>
        <v-card-title class="primary white--text">Select a film stock</v-card-title>
        <v-list>
          <v-list-group v-for="(item, i) in $store.state.masterData.filmStocks.groupedData" :key="i">
            <template v-slot:activator>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </template>

            <v-list-item v-for="stock in item.stocks" :key="stock.id" two-line @click="selectStock(stock)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ stock.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-row>
                    <v-col cols="6">
                      <v-icon small>mdi-film</v-icon>
                      {{ $t("masterData.filmType." + stock.type) }}
                    </v-col>
                    <v-col cols="6">
                      <v-icon small>mdi-speedometer</v-icon>
                      ISO {{ stock.iso }}
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "FilmStockSelector",
  props: ["value"],
  data: () => ({
    dialogOpen: false
  }),
  methods: {
    selectStock: function (stock) {
      this.dialogOpen = false
      this.$emit("input", stock.id)
      console.debug("Selected stock " + stock.id)
    },
  },
  computed: {
    selectedFilmStockName() {
      const filmStock = this.value !== undefined ? this.$store.state.masterData.filmStocks.cache[this.value] : undefined;
      return filmStock !== undefined ? filmStock.manufacturer + " " + filmStock.name: undefined;
    }
  }
}
</script>

<style scoped>

</style>