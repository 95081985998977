import {handleGenericPersistenceError, StorageKeys} from "@/services/storage/Persistence";
import localforage from "localforage";
import {SyncConflictError, SyncError, SyncNetworkError} from "@/services/storage/SyncError";
import NotificationUtils from "@/services/util/NotificationUtils";
import {i18n} from "@/plugins/i18n";
import store from "@/store";

const SYNC_INTERVAL = 5000
let syncRunning = false

const syncHandlers = {}

function setSyncRunningStatus(status) {
  syncRunning = status
  console.debug("Sync running: " + status)
}

export const SyncActions = {
  NEW_INVENTORY_ITEM: "newInventoryItem",
  UPDATE_INVENTORY_ITEM: "updateInventoryItem"
}

export function registerSyncHandler(actionKey, handler) {
  console.debug("Registering sync handler ", actionKey)
  syncHandlers[actionKey] = handler
}

export function queueActionForSync(actionKey, objectType, objectId, item) {
  if (store.state.view.syncError.show) {
    NotificationUtils.showErrorNotification("common.error.brokenSync")
  }
  let newSyncTask = new SyncTask(actionKey, objectType, objectId, item);

  // TODO: How to handle actions when the sync is currently running? -> Must be tested more in detail, but it should work

  return localforage.getItem(StorageKeys.SYNC_QUEUE).then(queuedTasks => {
    if (!queuedTasks) {
      queuedTasks = [];
    }

    let duplicateTask = false
    for (let task of queuedTasks) {
      if (task.objectType === newSyncTask.objectType && task.objectId === newSyncTask.objectId) {
        task.item
        duplicateTask = true
        break
      }
    }
    if (!duplicateTask) {
      queuedTasks.push(newSyncTask)
    }
    return localforage.setItem(StorageKeys.SYNC_QUEUE, queuedTasks)
      .then(r => {
        if (duplicateTask) {
          console.debug("Replaced existing sync task for", objectType, objectId)
        } else {
          console.debug("Queued new sync task for", objectType, objectId)
        }
        return startSyncQueue()
      })
      .catch(handleGenericPersistenceError)
  })
    .catch(handleGenericPersistenceError)
}

async function removeFirstFromQueue() {
  let queueReloaded = await localforage.getItem(StorageKeys.SYNC_QUEUE)
  queueReloaded.shift()
  return localforage.setItem(StorageKeys.SYNC_QUEUE, queueReloaded)
}

async function startSyncQueue() {
  if (store.state.view.syncError.show) {
    console.debug("Sync is in error state - not running any operation")
    return
  }
  if (syncRunning === false) {
    setSyncRunningStatus(true)
    try {
      await runSyncLoop()
    } catch (e) {
      if (e instanceof SyncNetworkError) {
        // FIXME: handle offline state
        console.debug("It looks like you're offline", e)
        return
      }
      let additionalInfo = undefined
      if (e instanceof SyncError) {
        if (e.messageKey) {
          additionalInfo = i18n.t(e.messageKey)
        }
      }
      NotificationUtils.showSyncError(e, additionalInfo)
    } finally {
      setSyncRunningStatus(false)
    }
  } else {
    console.debug("Sync already running")
  }
}

async function runSyncLoop() {
  let taskCount =  0
  while (true) {
    taskCount++
    const queue = await localforage.getItem(StorageKeys.SYNC_QUEUE)
    if (queue && queue.length > 0) {
      const task = queue[0]
      NotificationUtils.startGlobalLoading()

      try {
        await processSyncTask(task)
        // Remove the task from the queue if everything went well
        await removeFirstFromQueue()
      } catch(taskErr) {
        if (taskErr instanceof SyncConflictError) {
          NotificationUtils.showErrorNotification("common.error.syncConflict", {syncObjectType: i18n.t("common.syncObjectType." + taskErr.objectType)})
          // Remove the task from the queue because we assume the action reloaded the latest state from remote
          await removeFirstFromQueue()
        } else {
          throw taskErr;
        }
      } finally {
          NotificationUtils.stopGlobalLoading()
      }
    } else {
      break
    }
  }
}

/**
 * Process a sync task from the sync queue and delegate it to the appropriate handler.
 * @param task {SyncTask}
 * @returns {Promise<void>}
 */
async function processSyncTask(task) {
  const actionKey = task.actionKey;
  console.debug("Begin processing sync task of type " + actionKey)

  const handler = syncHandlers[actionKey]
  if (handler === undefined || !(handler instanceof Function)) {
    throw new SyncError("Unsupported handler", actionKey)
  }
  await handler(task.actionKey, task.item)

  console.debug("Finished processing sync task of type " + actionKey)
}

class SyncTask {
  actionKey;
  item;
  objectType;
  objectId;


  constructor(actionKey, objectType, objectId, item) {
    this.actionKey = actionKey;
    this.item = item;
    this.objectType = objectType;
    this.objectId = objectId;
  }
}

setInterval(startSyncQueue, SYNC_INTERVAL)