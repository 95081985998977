import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OrderView from "@/views/OrderView.vue";
import DetailOrderView from "@/views/DetailOrderView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import SettingsView from "@/views/SettingsView.vue";
import InventoryView from "@/views/InventoryView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/orders",
    name: "orders",
    component: OrderView,
    meta: {
      showFilterIcon: true,
      supportsArchivedFilter: true
    }
  },
  {
    path: "/orders/detail",
    name: "orderDetail",
    component: DetailOrderView,
    meta: {
      dontRenderElevation: true
    }
  },
  {
    path: "/inventory",
    name: "inventory",
    component: InventoryView,
    meta: {
      showFilterIcon: true,
      supportsFilmFilter: true
    }
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
  },
  {
    path: '*',
    component: NotFoundView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
