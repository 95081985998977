import Vlf from 'vlf'
import localforage from 'localforage'


localforage.config({
  name        : 'fct',
  version     : 1.0,
  storeName   : 'appdata',
  description : 'FCT AppData'
});

console.debug("LocalForage initialized")

export default Vlf;