import FilmStockService from "@/services/web/FilmStockService";
import store from "@/store";
import localforage from "localforage";
import {handleGenericPersistenceError, StorageKeys} from "@/services/storage/Persistence";
import CommonUtils from "@/services/util/CommonUtils";
import InventoryService from "@/services/app/LocalInventoryService";
import AuthenticationService from "@/services/app/AuthenticationService";

async function performFilmStockSync() {
    store.commit("startGlobalLoading")

    console.debug("Loading available film stocks from remote")
    try {
        const result = await FilmStockService.fetchAllFilmStocksSinceLastSync()
        let groupedData = result.data
        if (groupedData && groupedData.length > 0) {
            let cache = FilmStockService.generateCacheKeys(groupedData)  // Cache with Film stock ID as index
            store.commit("setFilmStockMasterData", {rawData: groupedData, cache})
            await localforage.setItem(StorageKeys.FILM_STOCKS, groupedData)
            await localforage.setItem(StorageKeys.LAST_SYNC_FILM_STOCKS, CommonUtils.getSecondsSinceEpoch())
        }
        store.commit("stopGlobalLoading")
    } catch (error) {
        // TODO: Handle error
        console.error("Loading master data failed", error)
        store.commit("stopGlobalLoading")
    }
}

export default {

    async initAll() {
        return Promise.all(
            [this.initializeFilmStocks(),
                InventoryService.initializeInventory(), AuthenticationService.checkAuthState()]
        )
    },

    clearAllLocalDataAndRestart() {
        localforage.removeItem(StorageKeys.LAST_SYNC_FILM_STOCKS)
    },

    initializeFilmStocks() {
        return localforage.getItem(StorageKeys.FILM_STOCKS)
            .then(stocks => {
                const allStocks = []
                if (stocks != null) {
                    for (let stock of stocks) {
                        allStocks.push(stock)
                    }
                }
                let groupedData = allStocks
                let cache = FilmStockService.generateCacheKeys(groupedData)  // Cache with Film stock ID as index
                store.commit("setFilmStockMasterData", {rawData: groupedData, cache})
                return performFilmStockSync()
            })
            .catch(handleGenericPersistenceError)
    },
}