import {v4 as uuidv4} from 'uuid';

function addLeadingZero(num) {
    if (num === undefined) {
        return "00";
    }
    return num >= 0 && num < 10 ? "0" + num : num;
}

export default {

    toAbsoluteUrl: function(url) {
        if (url === undefined) {
            throw new Error("Undefined service URL - check environment configuration")
        }
        let stripped = this.stripEndingSlash(url)
        if (url.indexOf("/") === 0) {   // If the URL begins with a "/", transform it to an absolute URL including the host
            stripped = window.location.protocol + "//" + window.location.host + stripped
        }
        return this.stripEndingSlash(stripped)
    },

    // the base URL may not end with a "/" or things will break
    stripEndingSlash: function(url) {
        if (url.indexOf("http") === 0 && url.lastIndexOf("/") === (url.length - 1)) {
            return url.substr(0, url.length - 1)
        } else {
            return url
        }
    },

    getSecondsSinceEpoch() {
        const now = new Date()
        const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000)
        const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000)
        return utcSecondsSinceEpoch
    },

    formatDateTime: function (localDateTime) {
        let year = localDateTime[0]
        let month = localDateTime[1]
        let day = localDateTime[2]
        let hour = localDateTime[3]
        let minute = localDateTime[4]
        let second = localDateTime[5]

        return `${addLeadingZero(day)}.${addLeadingZero(month)}.${year} ${addLeadingZero(hour)}:${addLeadingZero(minute)}:${addLeadingZero(second)}`
    },

    formatDate: function (localDate) {
        let year = localDate[0]
        let month = localDate[1]
        let day = localDate[2]

        return `${addLeadingZero(day)}.${addLeadingZero(month)}.${year}`
    },

    /**
     * Generates a new UUID string.
     * @returns {string|*}
     */
    generateUuid: function() {
        return uuidv4()
    },

    toDateObjectFromLocalDateTime: function(localDateTime) {
        let year = localDateTime[0]
        let month = localDateTime[1]
        let day = localDateTime[2]
        let hour = localDateTime[3]
        let minute = localDateTime[4]
        let second = localDateTime[5]

        return new Date(year, month - 1, day, hour, minute, second)
    },

    formatCurrency(value) {
        return (Math.round(value * 100) / 100).toFixed(2);
    },

    addLeadingZero
}