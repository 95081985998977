import HTTP from "@/services/util/HTTP";
import localforage from "localforage";
import {StorageKeys} from "@/services/storage/Persistence";

export default {
  fetchAllFilmStocksSinceLastSync() {
    return localforage.getItem(StorageKeys.LAST_SYNC_FILM_STOCKS).then(lastSync => {
      if (!lastSync) {
        lastSync = 0
      }
      return HTTP.get("/filmstocks", {
        params: {
          "onlyIfNewer": lastSync
        }
      })
    })
  },
  generateCacheKeys(groupedData) {
    let cache = {};
    for (let manufacturer of groupedData) {
      for (let stock of manufacturer.stocks) {
         cache[stock.id] = {
           id: stock.id,
           manufacturer: manufacturer.name,
           name: stock.name,
           iso: stock.iso,
           type: stock.type
         }
      }
    }

    return cache;
  }
}