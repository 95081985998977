import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {LOCALSTORAGE_I18N_KEY} from "@/services/Constants";

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const localStorageLanguage = localStorage.getItem(LOCALSTORAGE_I18N_KEY)
const browserLocale = navigator.language ? navigator.language.split('-')[0] : undefined

export let i18n = new VueI18n({
  locale: localStorageLanguage || browserLocale || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

export const locales = [
  {text: 'English', value: 'en'},
  {text: 'Deutsch', value: 'de'},
  {text: 'Norsk', value: 'no'},
  {text: '日本語', value: 'ja'},
]

export function changeLanguage(newLocale) {
  i18n.locale = newLocale
  localStorage.setItem(LOCALSTORAGE_I18N_KEY, newLocale)
}