import {ARCHIVE_FILTER, GENERIC_FILTER_ALL} from "@/services/Constants";

export default {

  auth: {
    sessionAvailable: false,
    passwordConfigured: false,
    token: undefined
  },

  /** Global application variables. */
  view: {
    /** Loading indicator on toolbar level */
    loading: false,
    /** Sync error information. */
    syncError: {
      show: false,
      additionalInfo: undefined,
      acknowledged: false
    },
    /** The current order which is displayed in the detail view. */
    currentOrder: {
      id: undefined,
      events: []
    },
    /** Any route-specific view configuration - objects below must match the route name. */
    routeSpecific: {
      orders: {
        currentArchiveFilter: ARCHIVE_FILTER.ACTIVE_ONLY
      },
      inventory: {
        currentFilmFormatFilter: GENERIC_FILTER_ALL,
        currentFilmTypeFilter: GENERIC_FILTER_ALL
      }
    }
  },

  /** Inventory */
  inventory: {
    items: []
  },

  /** Master data */

  masterData: {
    initialized: false,
    /** Film stocks. */
    filmStocks: {
      /** Stocks grouped by their manufacturers. */
      groupedData: [],
      /** Stocks indexed by their ID. */
      cache: {}
    }
  }
}