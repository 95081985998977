import router from "@/router";

export default {
  setSessionAvailable(state, newValue) {
    state.auth.sessionAvailable = newValue
  },
  setPasswordSet(state, newValue) {
    state.auth.passwordConfigured = newValue
  },
  setAuthToken(state, newValue) {
    state.auth.token = newValue
  },

  startGlobalLoading(state) {
    state.view.loading += 1
  },
  stopGlobalLoading(state) {
    state.view.loading -= 1
  },
  setOpenOrder(state, newOrder) {
    state.view.currentOrder = newOrder
  },
  setCurrentRouteArchiveFilter(state, newValue) {
    const currentRoute = router.currentRoute.name
    state.view.routeSpecific[currentRoute].currentArchiveFilter = newValue
  },
  setCurrentFilmFormatFilter(state, newValue) {
    const currentRoute = router.currentRoute.name
    state.view.routeSpecific[currentRoute].currentFilmFormatFilter = newValue
  },
  setCurrentFilmTypeFilter(state, newValue) {
    const currentRoute = router.currentRoute.name
    state.view.routeSpecific[currentRoute].currentFilmTypeFilter = newValue
  },

  showSyncError(state, additionalInfo) {
    state.view.syncError.show = true
    state.view.syncError.additionalInfo = additionalInfo
  },
  acknowledgeSyncError(state) {
    state.view.syncError.acknowledged = true
  },

  /* Master data mutations */
  setFilmStockMasterData(state, {rawData: groupedData, cache}) {
    state.masterData.filmStocks.groupedData = groupedData
    state.masterData.filmStocks.cache = cache
  },

  /* Inventory item mutations */
  setInventoryItems(state, items) {
    state.inventory.items = items
  },
  newInventoryItem(state, newItem) {
    state.inventory.items.push(newItem)
  },
  removeInventoryItem(state, deleteId) {
    let i = state.inventory.items.map(item => item.id).indexOf(deleteId) // find index of item
    state.inventory.items.splice(i, 1)
  },
  updateInventoryItem(state, updatedItem) {
    for (let item of state.inventory.items) {
      if (item.id === updatedItem.id) {
        item.format = updatedItem.format
        item.stockId = updatedItem.stockId
        item.capacity = updatedItem.capacity
        item.freezerCount = updatedItem.freezerCount
        item.fridgeCount = updatedItem.fridgeCount
        item.bagCount = updatedItem.bagCount
        item.version = updatedItem.version
        item.updatePending = updatedItem.updatePending
        break
      }
    }
  },
  /** Update the internal version counter for a single inventory item.  */
  setInventoryItemVersion(state, {itemId, newVersion}) {
    for (let item of state.inventory.items) {
      if (item.id === itemId) {
        item.version = newVersion
        item.updatePending = false
        break
      }
    }
  }
}