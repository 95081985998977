<template>
  <div>
    <TopBar/>

    <v-main>
      <v-fade-transition mode="out-in">
        <router-view/>
      </v-fade-transition>
    </v-main>

    <SyncErrorDialog/>
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
import TopBar from "@/components/TopBar.vue";
import SyncErrorDialog from "@/components/dialog/SyncErrorDialog.vue";

export default {
  name: "AppLayout",
  components: {SyncErrorDialog, TopBar, Notification},
}
</script>
