<template>
  <div class="home">
    <v-container>

      <v-img :src="require('../assets/logo.svg')" height="150px" contain class="mb-2" alt="FCT logo"></v-img>

      <v-row dense>
        <v-col cols="12">
          <v-card hover color="primary darken-1" dark :disabled="item.disabled" class="mb-3"
                  @click="!item.disabled ? $router.push({name: item.route}) : false" v-for="item in items" :key="item.i18nKey">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5">
                  {{  $t("view.home.links." + item.i18nKey + ".title") }}
                </v-card-title>

                <v-card-subtitle>{{  $t("view.home.links." + item.i18nKey + ".description") }}</v-card-subtitle>
              </div>

              <v-avatar
                  class="ma-3"
                  size="125"
                  tile
              >
                <v-icon size="100" color="white">{{ item.icon }}</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>

      </v-row>
    </v-container>


  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {},
  data: () => ({
    items: [{
      "i18nKey": "orders",
      "icon": "mdi-truck-fast",
      "route": "orders"
    }, {
      "i18nKey": "inventory",
      "icon": "mdi-clipboard-text",
      "route": "inventory"
    }, {
      "i18nKey": "record",
      "icon": "mdi-camera",
      "disabled": true
    }]
  })
}
</script>
