import HTTP from "@/services/util/HTTP";

export default {

  /**
   * Load all inventory items from remote.
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  fetchAll() {
    return HTTP.get("/inventory")
  },

  /**
   * Creates a new Inventory item at the remote backend.
   * @param item {InventoryItem}
   */
  createNewItem(item) {
    return HTTP.post("/inventory", {
        id: item.id,
        stockId: item.stockId,
        format: item.format,
        capacity: item.capacity,
        freezerCount: item.freezerCount,
        fridgeCount: item.fridgeCount,
        bagCount: item.bagCount
      }
    )
  },

  /**
   * Updates the given inventory item  at the remote backend.
   * @param id {string}
   * @param version {integer}
   * @param item {InventoryItem}
   */
  updateItem(id, version, item) {
    return HTTP.put("/inventory/" + id, {
        version: version,
        stockId: item.stockId,
        format: item.format,
        capacity: item.capacity,
        freezerCount: item.freezerCount,
        fridgeCount: item.fridgeCount,
        bagCount: item.bagCount
      }
    )
  },
  fetchItem(itemId) {
    return HTTP.get("/inventory/" + itemId)
  }
}