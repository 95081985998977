import localforage from "localforage";
import {handleGenericPersistenceError, StorageKeys} from "@/services/storage/Persistence";
import {queueActionForSync, SyncActions} from "@/services/storage/SyncService";
import storageHelper from "@/store/storageHelper";

export default {
  /**
   * Create a new inventory item in vuex, localforage and add it to the sync queue.
   * @param commit
   * @param newItem {InventoryItem}
   */
  newInventoryItem: function ({commit}, newItem) {
    commit("newInventoryItem", newItem)
    return storageHelper.newInventoryItem(newItem, true)
  },

  /**
   * Updates the given inventory item in vuex, localforage and adds it to the sync queue.
   * @param commit Commit
   * @param updatedItem {InventoryItem}
   */
  updateInventoryItem: function ({commit}, updatedItem) {
    commit("updateInventoryItem", updatedItem)
    return storageHelper.updateInventoryItem(updatedItem, true)
  },

  setInventoryItemVersion: function ({commit}, {itemId, newVersion}) {
    commit("setInventoryItemVersion", {itemId, newVersion})
    return storageHelper.setInventoryItemVersion(itemId, newVersion)
  }
}