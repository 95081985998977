import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c(VContainer,[_c(VImg,{staticClass:"mb-2",attrs:{"src":require('../assets/logo.svg'),"height":"150px","contain":"","alt":"FCT logo"}}),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},_vm._l((_vm.items),function(item){return _c(VCard,{key:item.i18nKey,staticClass:"mb-3",attrs:{"hover":"","color":"primary darken-1","dark":"","disabled":item.disabled},on:{"click":function($event){!item.disabled ? _vm.$router.push({name: item.route}) : false}}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("view.home.links." + item.i18nKey + ".title"))+" ")]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.$t("view.home.links." + item.i18nKey + ".description")))])],1),_c(VAvatar,{staticClass:"ma-3",attrs:{"size":"125","tile":""}},[_c(VIcon,{attrs:{"size":"100","color":"white"}},[_vm._v(_vm._s(item.icon))])],1)],1)])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }