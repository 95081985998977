import store from "@/store";

let notificationHandler

/** Register a handler function to be triggered on UI notifications. Used to decouple UI and JS logic. */
export function registerNotificationHandler(nf) {
    notificationHandler = nf
}

export function unregisterNotificationHandler() {
    notificationHandler = undefined
}

export default {
    /**
     * Shows a regular notification.
     * @param messageKey The key of the message to display.
     */
    showNotification(messageKey, params) {
        if (notificationHandler) {
            notificationHandler(messageKey, params)
        } else {
            console.warn("Couldn't send notification:", messageKey, "no notification handler configured")
        }
    },

    /**
     * Shows a notification formatted as an error.
     * @param messageKey The key of the message to display.
     */
    showErrorNotification(messageKey, params) {
        if (notificationHandler) {
            notificationHandler(messageKey, params, "error")
        } else {
            console.warn("Couldn't send notification:", messageKey, "no notification handler configured")
        }
    },

    /**
     * Shows a notification formatted as an error.
     * @param messageKey The key of the message to display.
     */
    showSuccessNotification(messageKey, params) {
        if (notificationHandler) {
            notificationHandler(messageKey, params, "success")
        } else {
            console.warn("Couldn't send notification:", messageKey, "no notification handler configured")
        }
    },

    showSyncError(error, additionalInfo) {
        console.error("An unexpected sync error occurred:", additionalInfo, error)
        store.commit("showSyncError", additionalInfo)
    },

    startGlobalLoading() {
        console.debug("Increasing load indicator")
        store.commit("startGlobalLoading")
    },

    stopGlobalLoading() {
        console.debug("Decreasing load indicator")
        store.commit("stopGlobalLoading")
    }
}