<template>
  <v-dialog max-width="600px" persistent v-model="show">
    <v-card>
      <v-form ref="loginForm" v-model="valid" @submit.prevent="doLogin">
        <v-card-text class="text-center">
          <p class="pt-4">
            <v-img :src="require('../../assets/logo.svg')" contain max-width="500px" max-height="400px"/>
          </p>

          <p class="text-h6">{{ $t("view.login.text") }}</p>

          <v-text-field
              v-model="password"
              type="password"
              :label="$t('view.setPassword.label.password')"
              :disabled="loading"
              required
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn text type="submit" :disabled="!valid || loading" :loading="loading">{{
              $t("common.button.submit")
            }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthenticationService from "@/services/app/AuthenticationService";
import {AUTHENTICATION_STATE} from "@/services/Constants";
import NotificationUtils from "@/services/util/NotificationUtils";
import MasterDataService from "@/services/app/MasterDataService";

export default {
  name: "LoginDialog",
  data() {
    return {
      show: true,
      loading: false,
      valid: false,

      password: undefined,
    }
  },
  methods: {
    doLogin() {
      AuthenticationService.login(this.password)
          .then(async response => {
            let authState = await AuthenticationService.checkAuthState()
            this.loading = false
            if (authState === AUTHENTICATION_STATE.AUTHENTICATED) {
              this.show = false
              console.log("Login successful")
              return MasterDataService.initAll()
            } else {
              console.error("Authentication state after login is in unexpected state", authState)
              NotificationUtils.showErrorNotification("common.error.unexpectedStatus")
            }
          }).catch(err => {
            NotificationUtils.showErrorNotification("view.login.wrongPassword")
            this.loading = false
            console.warn(err)
          }
      )
    },
  }
}
</script>

<style scoped>

</style>