import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"450px","persistent":""},model:{value:(_vm.updateExists),callback:function ($$v) {_vm.updateExists=$$v},expression:"updateExists"}},[_c(VCard,[_c(VCardText,{staticClass:"text-center"},[_c('p',{staticClass:"pt-4"},[_c(VIcon,{attrs:{"size":"100px","color":"primary"}},[_vm._v("mdi-update")])],1),_c('p',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t("view.update.text")))])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","loading":_vm.refreshing},on:{"click":_vm.refreshApp}},[_vm._v(_vm._s(_vm.$t("view.update.reload")))]),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }