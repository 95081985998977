<template>
  <div>
    <v-list
        subheader
        three-line
    >
      <v-subheader>{{ $t("view.settings.general.label") }}</v-subheader>

      <v-list-item-group>
        <v-list-item>
          <v-list-item-action></v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ $t("view.settings.general.language.label") }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t("view.settings.general.language.text") }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item disabled>
          <v-list-item-action></v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ $t("view.settings.general.cron.label") }}</v-list-item-title>
            <v-list-item-subtitle v-if="remoteConfiguration.cronExpression !== undefined">
              {{ $t("view.settings.general.cron.text") }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>{{ $t("view.settings.general.cron.disabled") }}</v-list-item-subtitle>
            <v-list-item-content v-if="remoteConfiguration.cronExpression !== undefined">
              {{ remoteConfiguration.cronExpression }}
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

    </v-list>

    <v-divider></v-divider>

    <v-list
        subheader
        three-line
    >
      <v-subheader>{{ $t("view.settings.sync.label") }}</v-subheader>

      <v-list-item-group>
        <v-list-item @click="resyncDialog.show = true">
          <v-list-item-action></v-list-item-action>

          <v-list-item-content >
            <v-list-item-title>{{ $t("view.settings.sync.clearQueue.label") }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t("view.settings.sync.clearQueue.text") }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list-item-group>

    </v-list>

    <v-divider></v-divider>

    <v-list subheader three-line :disabled="loading">
      <v-subheader>{{ $t("view.settings.notifications.label") }}</v-subheader>

      <v-list-item-group>
        <v-list-item disabled>
          <v-list-item-action>
            <v-checkbox disabled v-model="remoteConfiguration.notificationsEnabled"></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ $t("view.settings.notifications.receiveNotifications.label") }}</v-list-item-title>
            <v-list-item-subtitle>{{
                $t("view.settings.notifications.receiveNotifications.text")
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :disabled="!remoteConfiguration.notificationsEnabled" @click="sendTestNotification">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("view.settings.notifications.testNotification.label") }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t("view.settings.notifications.testNotification.text") }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-dialog v-model="resyncDialog.show" :persistent="resyncDialog.loading">
      <v-card>
        <v-card-title class="error mb-4">
          <v-icon>mdi-alert</v-icon>
          {{ $t("view.settings.sync.clearQueue.dialog.title") }}
        </v-card-title>

        <v-card-text>
          {{ $t("view.settings.sync.clearQueue.dialog.text") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :loading="resyncDialog.loading" :disabled="resyncDialog.loading"
                 @click="performResync">
            {{ $t("common.button.yes") }}
          </v-btn>
          <v-btn text @click="resyncDialog.show = false" :disabled="resyncDialog.loading">
            {{ $t("common.button.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ConfigurationService from "@/services/web/ConfigurationService";
import {NETWORK_ERROR} from "@/services/Constants";
import NotificationUtils from "@/services/util/NotificationUtils";
import {resetRemoteDataStorage} from "@/services/storage/Persistence";

export default {
  name: "SettingsView",
  data: () => ({
    remoteConfiguration: {
      notificationsEnabled: false,
      cronExpression: undefined
    },
    loading: false,
    resyncDialog: {
      show: false,
      loading: false
    }
  }),
  methods: {
    loadCurrentConfiguration() {
      console.debug("Loading remote configuration...")
      this.loading = true
      this.$store.commit("startGlobalLoading")
      ConfigurationService.loadCurrentConfiguration()
          .then(response => {
            this.loading = false
            this.$store.commit("stopGlobalLoading")
            this.remoteConfiguration = response.data
          })
          .catch(error => {
            this.loading = false
            if (error.message === NETWORK_ERROR) {
              NotificationUtils.showErrorNotification('common.error.network')
            } else if (error.response) {
              switch (error.response.status) {
                default:
                  NotificationUtils.showErrorNotification("common.error.unexpectedStatus", {status: error.response.status});
                  break;
              }
            } else {
              NotificationUtils.showErrorNotification('common.error.unexpected')
            }
            this.$store.commit("stopGlobalLoading")
            this.handleError(error)
          })
    },
    sendTestNotification() {
      this.$store.commit("startGlobalLoading")
      ConfigurationService.sendTestNotification()
          .then(() => {
            NotificationUtils.showSuccessNotification("view.settings.notifications.testNotification.sent")
            this.$store.commit("stopGlobalLoading")
          })
          .catch(error => {
            this.loading = false
            if (error.message === NETWORK_ERROR) {
              NotificationUtils.showErrorNotification('common.error.network')
            } else if (error.response) {
              switch (error.response.status) {
                case 429:
                  NotificationUtils.showErrorNotification("view.settings.notifications.testNotification.tooManyRequests");
                  break;
                default:
                  NotificationUtils.showErrorNotification("common.error.unexpectedStatus", {status: error.response.status});
                  break;
              }
            } else {
              NotificationUtils.showErrorNotification('common.error.unexpected')
            }
            this.$store.commit("stopGlobalLoading")
            this.handleError(error)
          })
    },
    performResync() {
      this.resyncDialog.loading = true
      resetRemoteDataStorage()
          .then(() =>
              window.location.reload()
          ).catch(err => {
            console.error("Unexpected error clearing remote data storage", err)
            NotificationUtils.showErrorNotification("common.error.unexpected")
          })
    }
  },
  mounted() {
    this.loadCurrentConfiguration()
  }
}
</script>

<style scoped>

</style>