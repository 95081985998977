<template>
  <v-dialog v-model="show" width="600px" persistent>
    <v-card>
      <v-card-text class="text-center">
        <p class="pt-4">
          <v-icon size="100px" color="primary">mdi-emoticon-dead-outline</v-icon>
        </p>
        <p class="text-h4 text-center">{{ $t('view.syncError.title')}}</p>
        <p v-if="$store.state.view.syncError.additionalInfo" class="text-center">{{ $t('view.syncError.additionalInfo') }} {{ $store.state.view.syncError.additionalInfo }}</p>
        <p class="font-weight-bold">{{ $t('view.syncError.helpText') }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="restart()" class="ml-4">
          {{$t('view.syncError.restart')}}
        </v-btn>
        <v-spacer/>
        <v-btn text @click="acknowledge()" class="ml-4">
          {{$t('common.button.cancel')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SyncErrorDialog",
  methods: {
    restart: function () {
      location.reload()
    },
    acknowledge: function () {
      this.$store.commit("acknowledgeSyncError")
    }
  },
  computed: {
    show: function() {
        return this.$store.state.view.syncError.show && !this.$store.state.view.syncError.acknowledged
    }
  },
}
</script>

<style scoped>

</style>