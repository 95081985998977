<template>
  <v-app id="fct" :style="{background: $vuetify.theme.themes[theme].background}">
    <UpdateDialog/>
    <AppLayout v-if="$store.state.auth.sessionAvailable"/>
    <SetPasswordDialog v-if="ready && !$store.state.auth.sessionAvailable && !$store.state.auth.passwordConfigured"/>
    <LoginDialog v-if="ready &&!$store.state.auth.sessionAvailable && $store.state.auth.passwordConfigured"/>
    <Notification/>
  </v-app>
</template>

<script>
import AppLayout from "@/components/AppLayout";
import UpdateDialog from "@/components/dialog/UpdateDialog.vue";
import MasterDataService from "@/services/app/MasterDataService";
import InventoryService from "@/services/app/LocalInventoryService";
import * as SyncHandlers from "@/services/storage/SyncHandlers";
import AuthenticationService from "@/services/app/AuthenticationService";
import SetPasswordDialog from "@/components/dialog/SetPasswordDialog.vue";
import Notification from "@/components/Notification.vue";
import LoginDialog from "@/components/dialog/LoginDialog.vue";

export default {
  components: {LoginDialog, Notification, SetPasswordDialog, UpdateDialog, AppLayout},
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  data: () => ({
    ready: false
  }),
  async created() {
    SyncHandlers.registerSyncHandlers()
    await AuthenticationService.init()
    this.ready = true


    if (AuthenticationService.hasSessionToken()) {
      await MasterDataService.initAll()
    } else {
      await AuthenticationService.checkAuthState()
    }
  }
}
</script>

<style>
html { overflow-y: auto }
</style>