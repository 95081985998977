import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VForm,{ref:"loginForm",on:{"submit":function($event){$event.preventDefault();return _vm.doLogin.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCardText,{staticClass:"text-center"},[_c('p',{staticClass:"pt-4"},[_c(VImg,{attrs:{"src":require('../../assets/logo.svg'),"contain":"","max-width":"500px","max-height":"400px"}})],1),_c('p',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("view.login.text")))]),_c(VTextField,{attrs:{"type":"password","label":_vm.$t('view.setPassword.label.password'),"disabled":_vm.loading,"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","type":"submit","disabled":!_vm.valid || _vm.loading,"loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t("common.button.submit"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }