import localforage from "localforage";
import {StorageKeys} from "@/services/storage/Persistence";
import HTTP from "@/services/util/HTTP";
import store from "@/store";
import {AUTHENTICATION_STATE} from "@/services/Constants";
import CommonUtils from "@/services/util/CommonUtils";

let sessionData


export default {

    async init() {
        sessionData = await localforage.getItem(StorageKeys.SESSION_DATA)
        if (sessionData == undefined) {
            sessionData = {}
        }
        store.commit("setSessionAvailable", sessionData.token !== undefined)
        store.commit("setAuthToken", sessionData.token)
        console.debug("Authentication system initialized")
    },

    hasSessionToken() {
        return sessionData ? sessionData.token !== undefined : false
    },

    async checkAuthState() {
        let state;
        await HTTP.get("/auth")
            .then(response => {
                console.debug("Current authentication state:", response.data)
                state = response.data
                if (response.data === AUTHENTICATION_STATE.NOT_CONFIGURED) {
                    store.commit("setPasswordSet", false)
                    store.commit("setSessionAvailable", false)
                    store.commit("setAuthToken", undefined)
                } else if (response.data === AUTHENTICATION_STATE.NOT_AUTHENTICATED) {
                    store.commit("setPasswordSet", true)
                    store.commit("setSessionAvailable", false)
                    store.commit("setAuthToken", undefined)
                } else if (response.data === AUTHENTICATION_STATE.AUTHENTICATED || response.data === AUTHENTICATION_STATE.DISABLED) {
                    store.commit("setPasswordSet", true)
                    store.commit("setSessionAvailable", true)
                } else {
                    throw new Error("Unknown authentication state:" + state)
                }
            })
        return state
    },

    login(password) {
        if (sessionData.clientId === undefined) {
            sessionData.clientId = CommonUtils.generateUuid()
            sessionData.clientName = "fct-app"
            localforage.setItem(StorageKeys.SESSION_DATA, sessionData)
        }

        return HTTP.post("/auth/session", {
            clientId: sessionData.clientId,
            clientName: sessionData.clientName,
            password
        }).then(response => {
            if (response && response.data) {
                let token = response.data.sessionToken
                if (token !== undefined) {
                    sessionData.token = token
                    localforage.setItem(StorageKeys.SESSION_DATA, sessionData)
                    store.commit("setAuthToken", token)
                    console.debug("Received session token", token)
                } else {
                    console.warn("Received no token after login")
                    throw new Error("Login failed")
                }
            } else {
                throw new Error("Login failed")
            }
        })
    },

    setRemotePassword(password) {
        return HTTP.post("/auth/password", {
            password: password
        })
    }
}