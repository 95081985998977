export const LOCALSTORAGE_I18N_KEY = "fct.language";

export const NETWORK_ERROR = "Network Error"
export const DEFAULT_APPBAR_ELEVATION = 4

export const STATE_TO_ICON = {
  "CREATED": "mdi-progress-question",
  "PROCESSING": "mdi-timer-sand",
  "DEVELOPED": "mdi-filmstrip-box-multiple",
  "SHIPPING": "mdi-truck-fast",
  "DELIVERED": "mdi-truck-check",
  "PICKED_UP": "mdi-package-variant-closed-check",
  "UNKNOWN": "mdi-emoticon-dead-outline"
}

export const SHOP_TYPES = [
  "DM_GERMANY"
]

export const FILM_TYPES = {
  COLOR_NEGATIVE: "COLOR_NEGATIVE",
  COLOR_POSITIVE: "COLOR_POSITIVE",
  BLACK_WHITE: "BLACK_WHITE"
}

export const FILM_FORMATS = {
  FILM_135: "FILM_135",
  FILM_120: "FILM_120",
}

export const ARCHIVE_FILTER = {
  ACTIVE_ONLY: "ACTIVE_ONLY",
  ARCHIVED: "ARCHIVED"
}

export const AUTHENTICATION_STATE = {
  AUTHENTICATED: "AUTHENTICATED",
  NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
  NOT_CONFIGURED: "NOT_CONFIGURED",
  DISABLED: "DISABLED"
}

export const GENERIC_FILTER_ALL = "ALL"